import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button as MUIButton } from '@mui/material';
import { makeStyles } from '@material-ui/styles';
import SKUList from '../../Utils/SampleSKU.json';
import Select from 'react-select';
import styled from 'styled-components';
import BootstrapProvider from '@bootstrap-styled/provider/lib/BootstrapProvider';



const useStyles = makeStyles({
  root: {
    '& .MuiDataGrid-root': {
      backgroundColor: 'white',
      fontFamily: 'Quicksand, Arial, sans-serif !important'
    },
    '& .MuiDataGrid-columnHeader': {
      color: 'hsl(26, 92%, 63%);'
    },
    '& .first-field': {
      color: 'hsl(26, 92%, 63%);',
      fontWeight: 'bold'
    },
    '& input[type=number]': {
        '-moz-appearance': 'textfield',
        '&::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
      }
  }
});

export default function DataGridModalComponent(props) {

  const [skuOptions, setSkuOptions] = useState(SKUList);
  const [selectedSKU, setSelectedSKU] = useState(null);

  const handleChangeSKU = (selected) => {
    setSelectedSKU(selected ? selected.value : null);
  }

  const classes = useStyles();

  const initialRows = props?.dgObj?.rows?.map(item => ({
    id: item,
    w1: 0, w2: 0, w3: 0, w4: 0, w5: 0, w6: 0, w7: 0, w8: 0, w9: 0, w10: 0, w11: 0, w12: 0,
    w13: 0, w14: 0
  }));

  // Load rows from sessionStorage if available
  const loadRows = () => {
    const savedRows = sessionStorage.getItem(props?.dgObj?.storageName);
    return savedRows ? JSON.parse(savedRows) : initialRows;
  };

  const [rows, setRows] = useState(loadRows);




  useEffect(() => {
    if (props?.dgObj?.storageName) {
      sessionStorage.setItem(props?.dgObj?.storageName, JSON.stringify(rows));
    }
  }, [rows, props?.dgObj?.storageName]);

  const processRowUpdate = (newRow, oldRow) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === oldRow.id ? newRow : row))
    );
    return newRow;
  };

  const myTheme = {
    'width': '100px',
  };

  // Custom styles for react-select using the styles prop
  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: 300,
      marginBottom : 40
    }),
  };

  return (
    <Wrapper>
      <Modal
        show={props.show}
        onHide={props.onHide}
        dialogClassName="my-dgmodal"
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props?.dgObj?.Name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className='control'>
            <label className='lbl-for-tb' >SKU Name : </label>
            <BootstrapProvider theme={myTheme}>
              <div className="drp">
                <Select
                  key="SKU"
                  options={skuOptions}
                  onChange={handleChangeSKU} 
                  styles={customStyles} 
                />
              </div>
            </BootstrapProvider>
          </div>

          <div className="control fileInput">
            <label className='lbl-for-tb' htmlFor="file2">Select file to upload</label>
            <input   className='txtbox fileinput'
               style={{ border  : "none" , marginBottom:'10px' }}
             type="file" id="file3" name="file3" accept=".xls,.xlsx,.csv"  data-sheetname='EOQ file' ></input>
          </div>

          <div>
            <Box sx={{ height: 400, width: '95%', overflow: 'auto' }} className={classes.root}>
              <DataGrid
                rows={rows}
                columns={props?.dgObj?.weeks}
                disableRowSelectionOnClick
                disableColumnSorting
                rowHeight={25}
                disableColumnMenu
                processRowUpdate={processRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}
              />
            </Box>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Wrapper>
  )
}

const Wrapper = styled.div`
.control {
  display: flex;
  align-items: center;
}
.lbl-for-tb {
  margin-right: 10px;
  color : var(--clr-primary-5);
}
.custom-select {
  width: 100px;
}
.fileinput{
  border : 1px solid black !important;
  margin-bottom:3px;
}
`;

