import React from 'react';
import styled from 'styled-components';
import Plot from 'react-plotly.js';

function GraphComponent({ data }) {
    console.log('graph');

    const arr_x = Object.keys(data["Quantity to be ordered"][0]);
    const arr_y = Object.values(data["Quantity to be ordered"][0]);
    const arr_xforcast = Object.keys(data["Forecast"][0]);
    const arr_yforcast = Object.values(data["Forecast"][0]);

    console.log(data);


    const arr = [{
        "x": arr_x,
        "y": arr_y,
        "type": "scatter",
        "name": "Quality to be ordered",
        "mode": "lines+markers+text",
        "text": arr_y.map(String),
        "marker": {
            "color": "green"
        }
    },
    {
        "x": arr_xforcast,
        "y": arr_yforcast,
        "type": "scatter",
        "name": "Forecast",
        "mode": "lines+text",
        "text": arr_yforcast.map(String),
        "marker": {
            "color": "red"
        }
    }]
    return (
        <Wrapper>
            <div class='container' >

                <Plot
                    data={arr}
                    layout={
                        {
                            width: 1200,
                            height: 750,
                            title: `Order Qty for SKU`,
                            xaxis: { title: "Weeks", showgrid: false, },
                            yaxis: { title: "Value", showgrid: false, },
                            showlegend: true,


                            legend: {
                                xanchor: "center",
                                yanchor: "top",
                                orientation: "h",

                                y: -0.5, // play with it
                                x: 0.5   // play with it
                            }
                        }
                    }
                />
            </div>
        </Wrapper>
    )
}


export default GraphComponent;

const Wrapper = styled.div`
.container{
    display:flex;
    flex-direction:column;
    justify-content: space-around;
}
  .graph{
    overflow:auto;
    width:100%;
  }
  .cust-table{
    width:fit-content;
  }
`