import React, { useMemo, useState }  from 'react'
import styled from 'styled-components';
import Select from 'react-select';
import { MultiSelect } from "react-multi-select-component";
import MetricsTableComponent from '../components/order/MetricsTableComponent';
import ErrorMetricsList from '../Utils/SampleErrorMetrics.json';
import SKUList from '../Utils/SampleSKU.json';
import MetricsList from '../Utils/SampleMetrics.json';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom"; 
import { ConfigSection2 } from '../components/configurationsalesperson';



function Page1() {

    const navigate = useNavigate();

    const [skuOptions, setSkuOptions] = useState(SKUList);
    const [metricsOptions, setMetricsOptions] = useState(MetricsList);
    const [selectedSKU, setSelectedSKU] = useState(SKUList);
    const [selectedMetric, setSelectedMetric] = useState({"value": "MAPE","label": "MAPE"});

    const handleChangeSKU = (selected) => {
        setSelectedSKU(selected);
    }

    const handleChangeMetrics = (selected) => {
        setSelectedMetric(selected);
    }

    const filteredData = useMemo(() => {
      const selectedValues = selectedSKU.map(item => item.value);      
      const output = ErrorMetricsList.filter(item => {  
        return (selectedValues.includes(item.sku)) && (item.metricname === selectedMetric.value);
        });
      return output;
      }, [selectedSKU, selectedMetric]);

    const columns = useMemo(
        () => [
          {
            Header: 'MetricName',
            accessor: 'metricname',
          },
          {
            Header: 'Model',
            accessor: 'model',
          },
          {
            Header: 'Training',
            accessor: 'training',
          },
          {
            Header: 'Validation',
            accessor: 'validation',
          }
        ],
        []
      );
    
    const handleIndentButtonClick = () => {
      setTimeout(() => {
        navigate("/OrderResult");
      }, 2000)
    }
    return (
        <Wrapper>
            <div className='section-center center-align page-width '>
                <div className="section-main">
                    <div className=" layout-article layout-title">
                        <p>Forecast Output</p>
                    </div>
                    <div className=" layout-article section-a">
                        <ConfigSection2 />
                    </div>

                    </div> 
                    <div className="section-main layout-article">
                    <div className="section-controls">
                        <div className='control'>
                            <label className='lbl-for-tb' >SKU Name : </label>
                            {/* <Select
                                key="SKU"
                                options={skuOptions}
                                onChange={handleChangeSKU}
                            /> */}
                              <MultiSelect
                                key="Scenario1"
                                options={skuOptions}
                                onChange={handleChangeSKU}
                                value={selectedSKU}
                                disableSearch={true}
                                className="custom-select"
                              />
                        </div>
                        <div className='control'>
                            <label className='lbl-for-tb' >Metric Name : </label>
                            <Select
                                key="SKU"
                                options={metricsOptions}
                                value={selectedMetric}
                                onChange={handleChangeMetrics}
                            />
                        </div>
                    </div>
                    <div className="section-table">

                    <MetricsTableComponent columns={columns} data={filteredData} />
                    </div>
                    <div className="section-submit">
                        <Button onClick={handleIndentButtonClick} > Indent Submit </Button>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default Page1;

const Wrapper = styled.div`

.mainbox{
  height:100%;
  display : flex;
  flex-direction : row;
  justify-content :space-around;
  padding:0;
  margin-top :0px;
  padding-top:20px;
}

.section-main{
    display:flex;
    flex-direction:column;
    row-gap:10px;

}

.lbl-for-tb {
  display: block;
  font-size:14px;
  color:var(--clr-primary-3); 
  margin-bottom:0.25rem;
  text-align:left;
  font-weight:bold;
  margin: auto 5px;
}

.section-controls{
    display:flex;
    justify-content:space-around;
    .control{
        width:300px;
    }
}

.section-table{
    height : 40vh;
    overflow-y:scroll;
}

`