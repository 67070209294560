import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import { postRequest, ApiEnums , getRequest} from '../../services/DataRequestService';
import { displayMessage , setValue } from '../../Utils/helper'
import UserService from '../../services/UserService'
import SectionD2 from './SectionD2';
import { useNavigate } from "react-router-dom";
import { newFormCreate } from '../../pages/wfCreate';
import { device } from '../../Utils/device';
import LoaderImage from '../../assets/loader.gif'




function SectionF(props) {
  let lblWfNameRef = useRef();
  let lblWfDescRef = useRef();
  let lblWfStartDateRef = useRef();
  let lblWfCutOffDateRef = useRef();
  //let lblWfEndDateRef = useRef();
  let lblWfGranularityRef = useRef();
  let lblWfModelsRef = useRef();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let form = JSON.parse(sessionStorage.getItem('form'));
    lblWfNameRef.current.innerText = form.workflow_name;
    lblWfDescRef.current.innerText = form.workflow_description;
    lblWfStartDateRef.current.innerText = form.start_date;
    lblWfCutOffDateRef.current.innerText = form.cut_off_date;
   // lblWfEndDateRef.current.innerText = form.end_date;
    lblWfGranularityRef.current.innerText = form.granularity;
    lblWfModelsRef.current.innerText = form.models.join(',');
  }, [])

  const btnOnClickHandler = async (e) => {
    let result = await UpdateWorkflow();
    setLoading(true);
    if(result){
      setTimeout(() => {
        setLoading(false);
        navigate("/page1");
      }, 5000);
    }else{
      setLoading(false);
    }
  }

  async function UpdateWorkflow(){

    let form = JSON.parse(sessionStorage.getItem('form'));

    if (form['workflow_name'] === "") {
      displayMessage("danger", "Workflow Name", "Workflow Name cannot be empty");
      return false;
    }

    let exists = await isWorkflowNameExists(form['workflow_name']);
    if(exists){
      displayMessage("danger", "Workflow Name", "Workflow Name already exists. Please use different workflow name ");
      return false;
    }
    if (form['workflow_description'] === "") {
      displayMessage("danger", "Workflow Description", "Workflow Description cannot be empty");
      return false;
    }

    let requestObject = {
      url: ApiEnums.update_workflow,
      config: {
        params: {
          user_id: UserService.getUserID()
        }
      },
      data: {
        object:'Workflow',
        status : 'completed',
        approval_status: 'approved',
        created_on : '30-01-2024',
        end_date : '30-01-2024',
        process_date : '20-01-2024',
        workflow_name: form['workflow_name'],
        workflow_description: form['workflow_description'],
        begin_date :form['start_date'],
        cut_off_date :form['cut_off_date'],
        granularity: form['granularity'],
        user_id : UserService.getUserID()
      }
    };
    const responseObject = await postRequest(requestObject);
    if (responseObject.status === "success") {
      setValue('session', 'form', "workflow_id", responseObject.data.workflow_id);
      displayMessage('success', 'Updated Successfully', 'Updated Successfully');
      return true;
    } else {
      displayMessage("danger", "Workflow", "Workflow update failed");
      console.log(responseObject);
      return false;
    }
  }

  async function isWorkflowNameExists(wfname){
    let requestObject = {
      url: ApiEnums.workflows,
      config: {
        params: {
          user_id: UserService.getUserID(),
          workflow_name : wfname
        }
      }
    };
    const responseObject = await getRequest(requestObject);
    if (responseObject.status === "success") {
      return true;
    } else {
      return false;
    }
  }

  const btnOnClickHandler_bkp = async (e) => {

    let form = JSON.parse(sessionStorage.getItem('form'));

    if (form['workflow_name'] === "") {
      displayMessage("danger", "Workflow Name", "Workflow Name cannot be empty");
      return false;
    }
    if (form['workflow_description'] === "") {
      displayMessage("danger", "Workflow Description", "Workflow Description cannot be empty");
      return false;
    }

    let begin_date = moment(form['start_date'], 'dd-MM-YYYY');
    let cutoff_date = moment(form['cut_off_date'], 'dd-MM-YYYY');
    let end_date = moment(form['end_date'], 'dd-MM-YYYY');
    if (!begin_date.isValid()) {
      displayMessage("danger", "Invalid Date", "Begin Date is invalid"); return false;
    }
    if (!cutoff_date.isValid()) {
      displayMessage("danger", "Invalid Date", "CutOff Date is invalid"); return false;
    }
    if (!end_date.isValid()) {
      displayMessage("danger", "Invalid Date", "End Date is invalid"); return false;
    }
    const diff = end_date.diff(begin_date, 'd');
    const diff2 = cutoff_date.diff(begin_date, 'd');
    const diff3 = end_date.diff(cutoff_date, 'd');
    if (diff < 0) {
      displayMessage("danger", "Date", "End date should not be lesser than begin date");
      return false;
    }
    if (diff2 < 0) {
      displayMessage("danger", "Date", "CutOff date should not be lesser than begin date");
      return false;
    }
    if (diff3 < 0) {
      displayMessage("danger", "Date", "End date should be greater than cutoff date");
      return false;
    }

    let granItem = document.querySelector(`div.gran input[type='radio']:checked`);
    if (form['granularity'] == '') {
      displayMessage("danger", "Granularity", "Please select a granularity");
      return false;
    }
    //model selection is mandatory
    if (form?.models.length == 0) {
      displayMessage("danger", "Select Model", "Please select a model");
      return false;
    }

    form['is_validated'] = true;

    props.loadingBar.current.continuousStart();
    let requestObject = {
      url: ApiEnums.workflow_form,
      config: {
        params: {
          user_id: UserService.getUserID()
        }
      },
      data: form
    }
    const responseObject = await postRequest(requestObject);
    if (responseObject.hasOwnProperty('workflow_id')) {
      sessionStorage.removeItem('form');
      newFormCreate();
      displayMessage("success", "Workflow Creation", "Workflow created Successfully");
      navigate("/wflist", { state: { wfid: responseObject.workflow_id } });
    } else {
      console.log(responseObject);
    }
    props.loadingBar.current.complete();
  }

 

  return (
    <Wrapper>
 <div className="control-section">


        <table>
          <tbody>
            <tr>
              <td> <label>Workflow Name</label></td>
              <td>
                <label ref={lblWfNameRef} id="lblWfName"></label>
              </td>
            </tr>
            <tr>
              <td>
                <label>Workflow Description</label>
              </td>
              <td>
                <label ref={lblWfDescRef}></label>
              </td>
            </tr>
            <tr>
              <td>
                <label>Start Date</label>
              </td>
              <td>
                <label ref={lblWfStartDateRef}></label>
              </td>
            </tr>
            <tr>
              <td>
                <label>Cut Off Date</label>
              </td>
              <td>
                <label ref={lblWfCutOffDateRef}></label>
              </td>
            </tr>
            {/* <tr>
              <td>
                <label>End Date</label>
              </td>
              <td>
                <label ref={lblWfEndDateRef}></label>
              </td>
            </tr> */}
            <tr>
              <td>
                <label>Granularity</label>
              </td>
              <td>
                <label ref={lblWfGranularityRef}></label>
              </td>
            </tr>
            <tr>
              <td>
                <label>Models</label>
              </td>
              <td>
                <label ref={lblWfModelsRef}>  </label>
              </td>
            </tr>
            <tr>
              <td>
                <label>Data Selections</label>
              </td>
              <td>
                <SectionD2 />
              </td>
            </tr>
          </tbody>
        </table>
        </div>

      {/* <SectionD2 /> */}
      <Button onClick={btnOnClickHandler} >Submit Workflow</Button>
      <br/>
      {
    loading && <>
    <img src={LoaderImage} width="50px" height="50px" />
    <br/>
    <span> Submitting Workflow </span>
    </>
  }
    </Wrapper>
  )
}

export default SectionF;

const Wrapper = styled.div`

position:relative;
text-align:right;
table {
	border-collapse: collapse;
  width:50vw;
}
table td {
	padding: 15px;
  width:max-content;
}
table td:nth-child(1) {
  width:40% !important;
}
table thead td {
	background-color: var(--clr-third-5);
	color: #ffffff;
	font-weight: bold;
	font-size: 13px;
	border: 1px solid var(--clr-third-2);
}
table tbody td:nth-child(2) {
  color:black;
}
table tbody td {
	color: var(--clr-primary-3);
	border: 1px solid var(--clr-third-2);
}
table tbody tr {
	background-color: var(--clr-third-5);
}
table tbody tr:nth-child(odd) {
	background-color: var(--clr-third-5);
}

@media screen and  (${device.tablet}) {
  table {
	border-collapse: collapse;
  width:90vw;
}
}

`