import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { Filters, MainLayout } from '../components';
import { useOutletContext } from "react-router-dom";
import { getStateList, getStoreList, getCategoryList, getDepartmentList, getProductsList, getModelList } from '../Utils/filterScripts';
import { useLocation } from 'react-router-dom';
import { modifyCombinations } from '../services/DataManipulationService'
import { getRequest, ApiEnums } from '../services/DataRequestService';
import UserService from '../services/UserService'
import {ErrorBoundary} from '../components';
import SampleMasterData from '../Utils/SampleMasterData.json'
import SampleWorkflow from '../Utils/SampleWorkflow.json'

export async function Loader() {
  var masterDataStr = localStorage.getItem("masterdata");
  if (!masterDataStr) {

    let masterDataLocalString = ''

    let requestObject = {
      url: ApiEnums.masterdata
    }
    //const data = await getRequest(requestObject);
    const data = SampleMasterData;

    let _states = getStateList(data.state);
    let _stores = getStoreList(data.store, data.state);
    let _categories = getCategoryList(data.category);
    let _departments = getDepartmentList(data.department, data.category);
    let _products = getProductsList(data.item, data.department, data.category);
    let _models = getModelList();
    let masterDataLocalObject = {
      states: _states,
      stores: _stores,
      categories: _categories,
      departments: _departments,
      products: _products,
      models: _models
    }
    masterDataLocalString = JSON.stringify(masterDataLocalObject);
    localStorage.setItem("masterdata", masterDataLocalString);
    masterDataStr = masterDataLocalString;
  }
  let masterData = JSON.parse(masterDataStr);
  return { masterData }
}

function WorkflowDetails() {
  const [masterData, setMasterData] = useState();
  const { state } = useLocation();
  const [wfData, setWfData] = useState(null);
  const { loadingBar } = useOutletContext();
  const [closeFlag, setCloseFlag] = useState(false);

  async function myLoader() {

    if (masterData === undefined) {

      var masterDataStr = localStorage.getItem("masterdata");
      if (!masterDataStr) {

        let masterDataLocalString = ''

        let requestObject = {
          url: ApiEnums.masterdata
        }
        //const data = await getRequest(requestObject);
        const data = SampleMasterData;

        let _states = getStateList(data.state);
        let _stores = getStoreList(data.store, data.state);
        let _categories = getCategoryList(data.category);
        let _departments = getDepartmentList(data.department, data.category);
        let _products = getProductsList(data.item, data.department, data.category);
        let _models = getModelList();
        let masterDataLocalObject = {
          states: _states,
          stores: _stores,
          categories: _categories,
          departments: _departments,
          products: _products,
          models: _models
        }
        masterDataLocalString = JSON.stringify(masterDataLocalObject);
        localStorage.setItem("masterdata", masterDataLocalString);
        masterDataStr = masterDataLocalString;
      }
      let masterData = JSON.parse(masterDataStr);
      setMasterData(masterData);
    }
  }

  useEffect(() => {
    const func = async () => {    
      loadingBar.current.continuousStart();
      await myLoader();
      let requestObject = {
        url: ApiEnums.workflow,
        config: {
          params: {
            user_id: UserService.getUserID(),
            workflow_id: state.wfId
          }
        }
      }
      // const responseObject = await getRequest(requestObject);
      const responseObject = SampleWorkflow;

      
      if (responseObject !== "error") {
        const combinations = modifyCombinations(responseObject.validCombinations);
        let result = {};
        result.form_input = responseObject.form_input;
        result.validCombinations = combinations;
        setWfData(dt => dt = result)
      }
    }

    func();
    loadingBar.current.complete();
  }, []);

  if (wfData === null) return;
  if (state === null) return;
  if(masterData === undefined || masterData === null) return;

  const closeFlagHandler = (e)=> {
    if(e.target.closest("#btnOpen")){
      setCloseFlag(false);
      }
      else{
        setCloseFlag(true);
      }
    }
  

  return (
    <Wrapper>     
      <div className={ !closeFlag ? 'products' : 'products-nosidebar' }> 
        {
          !closeFlag && <Filters masterData={masterData} wfData={wfData.form_input} closeHandler={closeFlagHandler}  />
        }
        
        <ErrorBoundary>
        <MainLayout masterData={masterData}
          combinations={wfData.validCombinations}
          workflowId={state.wfId}
          loadingBar={loadingBar}
          closeHandler={closeFlagHandler} 
          IsClose={closeFlag}
        />
        </ErrorBoundary>
      </div>
    </Wrapper>
  )
      }

const Wrapper = styled.div`
  .products {
    display: grid;
    gap: 0rem 0.3rem;
    margin: 0 0;
    height:100%; 
    background:#f1f3f6;
  }
  @media (min-width: 768px) {
    .products {
      grid-template-columns: min-content 1fr;
      min-height: 78vh;
    }

    .products-nosidebar{
      grid-template-columns:1fr;
      min-height: 78vh;
    }
  }
`

export default WorkflowDetails;
