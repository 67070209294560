import React, { useEffect, useRef, forwardRef, createRef } from 'react'
import { getValue, setValue, displayMessage  } from '../../Utils/helper'
import { getRequest, ApiEnums } from '../../services/DataRequestService';
import styled from 'styled-components';
import UserService from '../../services/UserService';
// import '../../assets/css/textbox.css'
import FileUploadComponent from './FileUploadComponent';

const SectionA  = forwardRef(function (props,refs) {
  let workflowNameRef = useRef();
  let workflowDescriptionRef = useRef();

  useEffect(() => {
    let formStr = sessionStorage.getItem('form');
    if (formStr) {
      let form = JSON.parse(formStr)
      workflowNameRef.current.value = form?.workflow_name;
    //  refs.a.current.value = form.workflow_name;
      workflowDescriptionRef.current.value = form?.workflow_description;
    } else {
      console.log('session data not available')
    }
  }, [])

  const txtWFNameOnBlurHandler = async (e) => {
    if(e.target.value === ""){
      e.target.classList.add('error-text');
      return;
    }else{
      e.target.classList.remove('error-text');
    }
    let wfNames = getValue('session','wf_names');
    if (wfNames === false) {
      let requestObject = {
        url: ApiEnums.wf_name_check,
        config: {
          params: {
            user_id: UserService.getUserID()
          }
        }
      }
      const responseData = await getRequest(requestObject);
      wfNames = responseData.wf_names;
      //sessionStorage.setItem('wf_names', JSON.stringify(responseData.wf_names));
      if(wfNames == undefined){
        wfNames=[];
      }
      setValue('session','wf_names', null ,JSON.stringify(responseData.wf_names));
      wfNames = Array.from(wfNames);
    }
    else{
      wfNames = JSON.parse(wfNames);
    }

    const result =wfNames.find(el => el.toLowerCase() === e.target.value.toLowerCase());
    if (result === undefined) {
      setValue('session', 'form', "workflow_name", e.target.value);
      e.target.classList.remove("error-text");
    } else {
    
      e.target.classList.add("error-text");
      displayMessage('danger', 'Already Exists', `Workflow name ${e.target.value} already exists please choose different workflow name`)
    }
  }

  const txtWFDescriptionOnBlurHandler = (e) => {
    if(e.target.value !== ""){
      e.target.classList.remove('error-text');
      setValue('session', 'form', "workflow_description", e.target.value);
    }else{
      e.target.classList.add('error-text');
    }
  }

  return (
    <Wrapper>
      <div className='basic-section'>
      <div className="wfname">
        <label className='mandatory  lbl-for-tb' >Workflow Name</label>
        <input type='text' ref={workflowNameRef}
        className='txtbox'
          onBlur={txtWFNameOnBlurHandler}
          maxLength={30} />          
        <label id="lblErrWfname" className="lbl-error" ></label>
      </div>

      <div className="wfdescription">    
        <label className='mandatory lbl-for-tb'>Workflow Description</label>
        <textarea ref={workflowDescriptionRef}
          onBlur={txtWFDescriptionOnBlurHandler}
          className='txtbox'          
          maxLength="200"
          rows="2"
          cols="70"
        ></textarea>
      </div>

      </div>
      <div className="">      
        <FileUploadComponent />
      </div>
    </Wrapper>
  )
})

export default SectionA;

const Wrapper = styled.div`



div.wfname, div.wfdescription {
    text-align:left;
    padding-bottom : 2rem;
    label {
      display:block;     
    }
  }
div.fileInput{
  text-align:left;
  padding-bottom : 2rem;
}


`