import React  from 'react'
import styled from 'styled-components';

function TableComponent({ data }) {
    if (data === null) {
      return null;
    }
  
    // Extract the column headers (months)
    const columns = data.Forecast ? Object.keys(data.Forecast[0]) : [];
  
    return (
      <Wrapper>
        <div className="container">
          <div className="graph">
            <table className="cust-table" id="dataGrid">
              <thead>
                <tr>
                  <th></th>
                  {columns.map((column) => (
                    <th key={column}>{column}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.keys(data).map((category) => (
                  <tr key={category}>
                    <th>{category}</th>
                    {columns.map((column) => (
                      <td key={column}>{data[category][0][column]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Wrapper>
    );
  }

export default TableComponent;

const Wrapper = styled.div`
.container{
    display:flex;
    flex-direction:column;
    justify-content: space-around;
}
  .graph{
    overflow:auto;
    width:100%;
  }
  .cust-table{
    width:fit-content;
  }
`