import React, { useState, useRef, createRef } from 'react';
import styled from 'styled-components';
import UserService from '../../services/UserService';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { ApiEnums, getRequest, postRequest } from '../../services/DataRequestService'
import { displayMessage , isEmpty } from '../../Utils/helper';
import useFetchData from '../../services/useFetchData'
import Select from 'react-select'
import { Spinner2 } from '../../components';

function UploadSKUs() {
  const [fileName, setFileName] = useState(null);
  const [excelData, setExcelData] = useState([]);
  const [selectedWF, setSelectedWF] = useState({});
  const fileRef = useRef();

  const apiUrl1 = `${process.env.REACT_APP_API_BASE_URL_2}${ApiEnums.get_wf_id}`;

  // get_sp API call with specific parameters
  const { data: data1, loading: loading1, error: error1 } = useFetchData(apiUrl1);
  if (loading1) return <Spinner2 />;
  if (error1) return <div>Error fetching Sales Persons List: {error1.message}</div>;

  function formatWFData(data) {
    let result = data?.map(item => ({ label: item.workflow_name, value: item.workflow_id }));
    return result;
  }


  // const [userId, setUserId] = useState(''); // To store user_id input
  // const [authToken, setAuthToken] = useState(''); // To store the authorization token

  const userId = (UserService.getUserID());
  const authToken = (UserService.getToken);

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      setFileName(file.name); // Set the filename in state
    } else {
      setFileName(''); // Reset filename if no file is selected
    }

    // Create a FileReader to read the file
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      // Get the first sheet name
      const firstSheet = workbook.SheetNames[0];

      // Convert the sheet to JSON format
      const worksheet = workbook.Sheets[firstSheet];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Set the Excel data to state
      setExcelData(jsonData);
    };

    // Read the file as an array buffer
    reader.readAsArrayBuffer(file);

  };


  // Function to handle file upload
  const handleUpload = async () => {
    if (!fileName) {
      displayMessage('danger','Error','Please select a file to upload.');
      return;
    }

    if (!userId) {
      displayMessage('danger','Error','Unable to fetch UserID. Please contact Admin.');
      return;
    }

    if(isEmpty(selectedWF)){
      displayMessage('danger','Error','Please choose workflow.');
      return;
    }

    try {
      // Create a FormData object to hold the file in binary format and the user_id
      const file = fileRef.current.files[0];
      const formData = new FormData();
      formData.append('file', file); // Append the file in binary format
      //formData.append('user_id', userId);    // Append the user ID

      // Perform the Axios POST request with authorization header
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL_2}${ApiEnums.upload_planned_sku_values}`, formData,
        {
          params: {
            user_id: userId,
            workflow_name: selectedWF?.label
          },
          headers: {
            'accept': 'application/json',
            'Content-Type': 'multipart/form-data', // Required for file uploads
            'Authorization': `Bearer ${authToken}`, // Authorization header with Bearer token
          },
        });
        console.log(response);
      alert('File uploaded successfully!');
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error uploading file. Please try again.');
    }
  };

  const onClickHandlerUploadFile = () => {
    // Handle file upload logic here
  };

  const handleWFSelectChange = (option) => {
    setSelectedWF(option);
  }

  return (
    <Wrapper>

      <div className="mycontainer">
        <div className="container">
          <div className="control-section">
            <div className="control-a">
              <label>Workflow:</label>
              <div className="select-control">
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  // defaultValue={{ label : data1?.data[0].name, value : data1?.data[0].id}}
                  isDisabled={false}
                  isClearable={false}
                  isSearchable={true}
                  name="sales-person"
                  options={formatWFData(data1.data)}
                  onChange={handleWFSelectChange}
                />
              </div>
            </div>
            <div className="control-b">
              <label>File to Upload:</label>
              <div className="form-control">
                <input
                  ref={fileRef}
                  type="file"
                  id="fileupload"
                  name="fileupload"
                  onChange={handleFileChange}
                />
              </div>
              <button className="upload-button" onClick={handleUpload}>
                Upload
              </button>
            </div>
            {/* <div className="control-c">
              <div className="download-link" style={{ visibility: 'hidden' }} >
                <a href="/samplefiles/sample01.xlsx" download="sample01.xlsx">
                  Download Sample Format
                </a>
              </div>
            </div> */}
          </div>


          {/* Display the HTML table if there's data */}
          {excelData.length > 0 && (
            <>
              <h4>Forecast values</h4>
              <table border="1">
                <thead>
                  <tr>
                    {excelData[0].map((header, index) => (
                      <th key={index}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {excelData.slice(1).map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>

    </Wrapper>
  );
}

export default UploadSKUs;

const Wrapper = styled.div`
.mycontainer{
  display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.control-section{
  display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width:80%;

    padding:10px;
    border:1px solid #E8E8E8;
    border-radius:5px;
    background-color:#E8E8E8;

}

.select-control{
  label{
    display:inline-block;
  }
  margin:auto 10px;
  width:20rem;
}

.control-b ,.control-a {
  display:flex;
  justify-content:left;
  align-items:center;
}

.control-a {
  width : 20rem;  
}



  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    padding: 20px;
  }

  
    /* file upload button */
input[type="file"]::file-selector-button {
  border-radius: 4px;
  padding: 0 16px;

  height: 100%;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  margin:5px 16px 0px 5px;
  transition: background-color 200ms;
}

/* file upload button hover state */
input[type="file"]::file-selector-button:hover {
  background-color: #f3f4f6;
}

/* file upload button active state */
input[type="file"]::file-selector-button:active {
  background-color: #e5e7eb;
}

  table{
    width:80% !important;
  }


  .form-control {
    margin-right:5px;
    height:35px;
    padding: 0px;
    input{
      border: none;
    width: 100%;
    }
}

  .row {
    margin-bottom: 20px;
    width: 100%;
    max-width: 400px;
    text-align: center;
  }

  .file-label {
    display: block;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
  }


  .upload-button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 5px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .upload-button:hover {
    background-color: #0056b3;
  }

  .download-link a {
    color: #007BFF;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
  }

  .download-link a:hover {
    color: #0056b3;
    text-decoration: underline;
  }
`;
