import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';



function SectionPromotion() {
    return (
        <Wrapper>
            <div className='itembox'>
                <div>Description</div>
                <div>From Date</div>
                <div>To Date</div>
                <div>Promotion Options</div>
            </div>
            <PromoList description={'this some is value'} />
            <PromoList description={'this some is value'} />
            <PromoList description={'this some is value'} />
            <PromoList description={'this some is value'} />
            <PromoList description={'this some is value'} />
            <PromoList description={'this some is value'} />
            <PromoList description={'this some is value'} />
            <PromoList description={'this some is value'} />

        </Wrapper>
    )
}

function PromoList({ description }) {
    const promotionOptions = [
        { label: 'no offer', value: 'no offer' },
        { label: 'Buy 1 Get 1', value: 'Buy 1 Get 1' },
        { label: 'some value', value: 'some value' }
    ]
    return (
        <>

            <div className='itembox'>
                <div className='description'>
                    <span>{description}</span>
                </div>
                <div className='description'>
                    <input type="date" name="fromdate" id="fromdate" />
                </div>
                <div className='description'>
                    <input type="date" name="todate" id="todate" />
                </div>
                <div className='description'>
                    <Select
                        className='opt'
                        defaultValue={promotionOptions[0]}
                        name="promotion"
                        options={promotionOptions}
                    />
                </div>
            </div>
        </>
    )
}

export default SectionPromotion;

const Wrapper = styled.div`
    .itembox{
        display:flex;
        justify-content:space-between;
        border : 1px solid black;
        width:700px;
        font-size:14px;
    }

    .opt{
 
    }

    .description{
        display:flex;
        align-items:center;
        text-align:center;
        padding-left:5px;
    }
`