import React, { useState , useEffect} from 'react'
import styled from 'styled-components';
import '../../assets/css/button01.css';
import TableMatrix from '../configurationsalesperson/TableMatrix';
import * as XLSX  from 'xlsx';
import { displayMessage } from '../../Utils/helper';

function TabularViewContainer2({workflowID }) {

    useEffect(() => {

    }, []); // Update models when data changes


    const handleClickBtnDownload = () => {
        let dom_element = document.getElementById('tablematrix1');
        var workbook = XLSX.utils.table_to_book(dom_element);
        const sheetNames = workbook.SheetNames;

        if (sheetNames.length > 0) {
              XLSX.writeFile(workbook, `skusforecastdata-${workflowID}` + '.xlsx');
        } else {
              displayMessage('warning', 'No Content', 'No Content to download');
        }
    }
    
   
    return (
        <Wrapper>    
              <h1>Tabular View</h1>    
     
            <div className='table-container' >
                <TableMatrix workflowID={workflowID} />
                <div className="controls">
                <button onClick={handleClickBtnDownload} className='button-1' >Export Data</button>
            </div> 
            </div>
      
        </Wrapper>
    )
}

export default TabularViewContainer2;

const Wrapper = styled.div`
display :flex;

flex-direction : column;
justify-content: left;
width:100%;

.table-container{
    width:fit-content;
    height:fit-content;
    overflow-y:auto;
}

.table-container::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: var(--clr-primary-1);
}

.table-container::-webkit-scrollbar
{
	width: 5px;
	background-color:  var(--clr-primary-1);
}

.table-container::-webkit-scrollbar-thumb
{
	background-color:  var(--clr-primary-10);
	border: 2px solid  var(--clr-primary-8);
}

.controls{
    text-align : right;
    margin-top:0.3rem;
}

.content{
    margin-top:10px !important;
}
`