import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import { postRequest, ApiEnums, getRequest } from '../../services/DataRequestService'
import { displayMessage, isEmpty } from '../../Utils/helper'
import TableComponent from './TableComponent'
import Select from 'react-select'
import Spinner2 from '../common/Spinner2'
import axios from 'axios';
import UserService from '../../services/UserService';

export default function Compare() {
  const [selectedWF, setSelectedWF] = useState({});
  const [WorkFlowList, setWorkFlowList] = useState([]);
  const [key, setKey] = useState(0);
  const [TableData, setTableData] = useState([]);
  const [updatedValues, setUpdatedValues] = useState({});
  const [groupedData, setGroupedData] = useState([]);
  const [uniqueWeeks, setUniqueWeeks] = useState([]);

  useEffect(() => {
    fetchWorkFlows();
  }, []);

  useEffect(() => {
    // Update grouped data and unique weeks when TableData changes
    setGroupedData(groupBySKU(TableData));
    setUniqueWeeks([...new Set(TableData.map(item => item.week))].sort((a, b) => a - b));
  }, [TableData]);

  const fetchWorkFlows = async () => {
    const apiUrl1 = `${process.env.REACT_APP_API_BASE_URL_2}${ApiEnums.get_wf_id}`;
    let requestObject = { url: apiUrl1 };
    const responseData = await getRequest(requestObject);
    if (responseData.status === "success") {
      setWorkFlowList(responseData.data);
    }
  };

  const fetchForecastData = async (workflow_name) => {
    const apiUrl = `${process.env.REACT_APP_API_BASE_URL_2}${ApiEnums.get_fp_data}`;
    let requestObject = {
      url: apiUrl,
      config: {
        params: {
          workflow_name: workflow_name
        }
      }
    };
    const responseData = await getRequest(requestObject);
    if (responseData.status === "success") {
      const newTableData = responseData?.data?.skus_data || [];
  
      // Set TableData and calculate grouped data and unique weeks immediately
      setTableData(newTableData);
      setGroupedData(groupBySKU(newTableData));
      setUniqueWeeks([...new Set(newTableData.map(item => item.week))].sort((a, b) => a - b));
    } else {
      // Reset table state on error
      setTableData([]);
      setGroupedData([]);
      setUniqueWeeks([]);
    }
  };

  function formatWFData(data) {
    return data?.map(item => ({ label: item.workflow_name, value: item.workflow_id }));
  }

  const handleWFSelectChange = async (option) => {
    setSelectedWF(option);
  
    // Clear previous table data before fetching new data
    setTableData([]);
    setGroupedData([]);
    setUniqueWeeks([]);
  
    // Fetch new data based on selected workflow
    fetchForecastData(option?.label);
  };
  const handleInputChange = (sku, week, value) => {
    setUpdatedValues((prevValues) => ({
      ...prevValues,
      [`${sku}_${week}`]: Number(value),
    }));
  };

  const handleSubmit = async () => {
    const formattedData = TableData.map(item => ({
      ...item,
      f_value: updatedValues[`${item.sku}_${item.week}`] || item.f_value,
    }));

    await UploadData(selectedWF.value, selectedWF.label, formattedData);
  };

  async function UploadData(wfid, wfname, payloaddata) {
    let requestObject = {
      url: ApiEnums.update_f_values,
      config: {
        params: {
          user_id: UserService.getUserID()
        }
      },
      data: {
        workflow_id: wfid,
        workflow_name: wfname,
        skus_data: payloaddata
      }
    };
    const responseObject = await postRequest(requestObject);
    if (responseObject.status === "success") {
      //displayMessage('success', 'Updated Successfully', 'Updated Successfully');
      UpdateWorkflow(wfid);
    } else {
      displayMessage('danger', 'Updated Failed', responseObject.message);
      console.log(responseObject);
    }
    setSelectedWF({});
  }


  async function UpdateWorkflow(wfId){
    let requestObject = {
      url: ApiEnums.update_workflow,
      config: {
        params: {
          user_id: UserService.getUserID()
        }
      },
      data: {
        id: wfId,
        approval_status: 'approved',
        user_id: UserService.getUserID()
      }
    };
    const responseObject = await postRequest(requestObject);
    if (responseObject.status === "success") {
      displayMessage('success', 'Updated Successfully', 'Updated Successfully');
    } else {
      displayMessage('danger', 'Updated Failed', responseObject.message);
      console.log(responseObject);
    }
  }

  // Helper function to group data by SKU
  const groupBySKU = (TableData) => {
    const result = {};
    TableData.forEach(item => {
      if (!result[item.sku]) {
        result[item.sku] = {};
      }
      result[item.sku][item.week] = { f_value: item.f_value, p_value: item.p_value };
    });
    return result;
  };

  return (
    <Wrapper>
      <div className="container layout-article">
        <div className="section-a">
          <label className='lbl-for-tb'>Select Workflow: </label>
          <div className="select-control">
            <Select
              className="basic-single"
              classNamePrefix="select"
              isDisabled={false}
              isClearable={false}
              isSearchable={true}
              name="sales-person"
              value={selectedWF.value ? selectedWF : null} 
              options={formatWFData(WorkFlowList)}
              onChange={handleWFSelectChange}
            />
          </div>
        </div>

        <Table border="1" cellPadding="5" cellSpacing="0">
          <thead>
            <tr>
              <th rowSpan="2">SKU</th>
              {uniqueWeeks.map(week => (
                <th colSpan="2" key={week}>Week {week}</th>
              ))}
            </tr>
            <tr>
              {uniqueWeeks.map(week => (
                <React.Fragment key={week}>
                  <th>Forecast</th>
                  <th>Planned</th>
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(groupedData).map(sku => (
              <tr key={sku}>
                <td>{sku}</td>
                {uniqueWeeks.map(week => (
                  <React.Fragment key={week}>
                    <td>
                      <input
                        type="number"
                        onChange={(e) => handleInputChange(sku, week, e.target.value)}
                        defaultValue={groupedData[sku][week]?.f_value || 0}
                      />
                    </td>
                    <td className='inactive'>{groupedData[sku][week]?.p_value || 0}</td>
                  </React.Fragment>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>

        <div className="btn-section">
          <button className='btn btn-primary' onClick={handleSubmit}>Approve</button>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .btn-section {
    padding: 5px;
    margin-top: 2rem;
  }
  .select-control {
    width: 20rem;
  }
  .section-a {
    display: flex;
    align-items: center;
    margin: 10px 0;
    label {
      height: 100%;
      line-height: 100%;
      margin-right: 5px;
    }
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  overflow-y: scroll;
  overflow-x: auto;
  font-size: 14px;

  tr {
    background: white !important;
  }

  tr > td > input {
    width: 100% !important;
  }

  tr > td {
    text-align: center;
    padding: 4px !important;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: var(--clr-primary-5);
    color: white;
  }

  td.inactive {
    background-color: #f5f5f5;
  }

  thead {
    th {
      padding: 8px !important;
      text-align: center !important;
    }
  }

  tbody {
    tr {
      padding: 0px !important;
    }
  }

  tbody tr:hover {
    background: none !important;
  }

  input[type="number"] {
    -moz-appearance: textfield;
    -webkit-appearance: none;
    appearance: none;
    padding: 10px;
    width: 100px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    width: 3rem;
    border-radius: 5px;
    padding: 0px 5px;
    text-align: center;
  }

  input:focus {
    outline: 1px solid #ccc;
    border: 1px solid #ccc;
  }
`;
