import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase, faCircleInfo, faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { getValue, setValue } from '../../Utils/helper'

const ModelList = [
  {
    "id": "ARIMA",
    "name": "ARIMA",
    "description": "The ARIMA model is a statistical workhorse for analyzing time series data. It forecasts future trends by considering past values and accounting for inherent patterns like seasonality.",
  },
  {
    "id": "AR",
    "name": "AR",
    "description": "An auto-regression model predicts future values in a time series by analyzing past observations. It assumes the future depends on a trend influenced by past data points.",
  },
  {
    "id": "TBAT",
    "name": "TBAT",
    "description": "The TBATS model is a statistical technique for forecasting time series data. It tackles trends, seasonal patterns (including complex ones), and inherent errors in the data using a combination of methods:  Trigonometric seasonality, Box-Cox transformation, ARIMA error handling, trend modeling, and seasonal components.",
  },
  {
    "id": "PROPHET",
    "name": "PROPHET",
    "description": "Prophet is a Facebook developed forecasting model designed for ease of use. It excels at capturing common time series features like holidays, weekends, and overall trends. It uses an additive model to make interpretable forecasts.",
  },
  {
    "id": "SARIMAX",
    "name": "SARIMAX",
    "description": "The SARIMAX model is a statistical method for forecasting time series data. It builds upon ARIMA models by incorporating seasonal effects, allowing it to capture recurring patterns across specific time intervals.",
  },
  {
    "id": "HOLT-WINTERS",
    "name": "HOLT-WINTERS",
    "description": "The Holt-Winters model is a forecasting technique for time series data. It captures underlying trends and seasonal patterns to predict future values, making it useful for scenarios with cyclical fluctuations.",
  }
]

function SectionE() {

  useEffect(() => {
    let models = getValue('session', 'form', 'models');
    if (models?.length > 0) {
      for (let model of models) {
        document.getElementById(model).checked = true;
      }
    }
  }, [])

  const OnChangeHandler = (e) => {
    /*
    1. Get ModelId and its status
    2. Get Existing Model Selected
    3. Append / Remove the model based on selected or unselected
    4. 
    */
    let selectedModel = e.target.id;
    let isChecked = e.target.checked;
    let models = getValue('session', 'form', 'models');
    if (isChecked) {
      models.push(selectedModel);
    } else {
      models = models.filter(m => m != selectedModel);
    }
    setValue('session', 'form', 'models', models);

  }


  return (
    <Wrapper>
      <div class="table-responsive">
        <table class="table dataselectiontable table-bordered">
          <thead>
            <tr>
              <th colspan="2">Listing For Selected Data </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="checkbox">
                  <label><input type="checkbox" value="" id="ARIMA" onChange={OnChangeHandler}  />ARIMA</label>
                </div>
              </td>
              <td>
                The ARIMA model is a statistical workhorse for analyzing time series data. It forecasts future trends by considering past values and accounting for inherent patterns like seasonality.
              </td>
            </tr>
            <tr>
              <td>
                <div class="checkbox">
                  <label><input type="checkbox" value="" id="AR" onChange={OnChangeHandler} />AR</label>
                </div>
              </td>
              <td>
                An auto-regression model predicts future values in a time series by analyzing past observations. It assumes the future depends on a trend influenced by past data points.
              </td>
            </tr>
            <tr>
              <td>
                <div class="checkbox">
                  <label><input type="checkbox" value="" id="TBAT" onChange={OnChangeHandler} />TBAT</label>
                </div>
              </td>
              <td>
                The TBATS model is a statistical technique for forecasting time series data. It tackles trends, seasonal patterns (including complex ones), and inherent errors in the data using a combination of methods: Trigonometric seasonality, Box-Cox transformation, ARIMA error handling, trend modeling, and seasonal components.
              </td>
            </tr>
            <tr>
              <td>
                <div class="checkbox">
                  <label><input type="checkbox" value="" id="PROPHET" onChange={OnChangeHandler} />PROPHET</label>
                </div>
              </td>
              <td>
                Prophet is a Facebook developed forecasting model designed for ease of use. It excels at capturing common time series features like holidays, weekends, and overall trends. It uses an additive model to make interpretable forecasts.
              </td>
            </tr>
            <tr>
              <td>
                <div class="checkbox">
                  <label><input type="checkbox" value="" id="SARIMAX" onChange={OnChangeHandler} />SARIMAX</label>
                </div>
              </td>
              <td>
                The SARIMAX model is a statistical method for forecasting time series data. It builds upon ARIMA models by incorporating seasonal effects, allowing it to capture recurring patterns across specific time intervals.
              </td>
            </tr>
            <tr>
              <td>
                <div class="checkbox">
                  <label><input type="checkbox" value="" id="HOLT-WINTERS" onChange={OnChangeHandler} />HOLT-WINTERS</label>
                </div>
              </td>
              <td>
                The Holt-Winters model is a forecasting technique for time series data. It captures underlying trends and seasonal patterns to predict future values, making it useful for scenarios with cyclical fluctuations.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Wrapper>
  )
}

export default SectionE;

const Wrapper = styled.div`
width:100%;
.models {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap : 0.5rem;
        width:100%;

    li {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: auto;
        margin: 0 auto;
        margin-bottom:0.2rem;
        label {        
            display: flex;
            flex-direction: column;
            border:1px solid var(--clr-primary-5);
            /* border-radius: 5px; */
            height: fit-content;
            font-style:normal;
            width:15rem;
            .model-name{
              font-size:20px;
              color : var(--clr-primary-3);
            }
            i.info{
              visibility:hidden;
            }
            i{
              color:var(--clr-primary-9);
            }
            
            font-size: 14px;
            div:first-child{
              display:flex;
              width:100%;
              flex-direction:row;
              align-items: center; /* Vertically center align items */
              justify-content:space-between;
             p{
              display: inline-flex; /* Change to inline-flex */
              align-items: center; /* Vertically center align text */
             }
             padding : 0 10px;
             
            }
            /* div:nth-child(2){
              text-align: left;
              padding: 0 0px 0px 3px;
              height: fit-content;
              font-size: 12px;
              span{
                display: block;
                overflow-y:auto;
                height: fit-content;

                &::-webkit-scrollbar-track
                  {
                    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                  }

                  &::-webkit-scrollbar
                  {
                    width: 8px;
                  }

                  &::-webkit-scrollbar-thumb
                  {
                    border-radius: 10px;
                    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
                  }

              }
            } */
            :hover{
              cursor:pointer;
              /* border-radius: 5px; */
              span:not(.detail){
                font-weight : bold;
              }
              i.check{
                color :var(--clr-primary-8);
              }
              + div.description > li.
            }        
          }
        input[type='checkbox'] {
          display:none;
        }
        input[type='checkbox'] + label:hover{
          background:var(--clr-primary-10);
          /* border-radius: 5px; */
          cursor:pointer;
        }
        input[type='checkbox']:checked + label{
          background:var(--clr-primary-9);
          /* border-radius: 5px; */
              span:not(.detail){
                font-weight : bold;
              }
              i.check{
                color :var(--clr-primary-5);
              }
        }
    }
}

.dataselectiontable { word-wrap: balance; border-color:black !important;}
table td, table th {
    border: 1px solid black !important;
    padding: 8px;
}

.dataselectiontable thead tr{background-color: #0C3C54 !important; color: #fff !important; text-align: left !important;}
.dataselectiontable thead tr th{text-align: left !important; vertical-align:middle !important; padding: 15px 8px !important;}
.dataselectiontable thead tr th:nth-child(1){width: 28% !important;}
.dataselectiontable tbody tr td{text-align: left !important; padding: 15px 8px !important;}
.dataselectiontable tbody tr td span{font-weight: 800;}
.dataselectiontable tbody tr td label{font-weight: 800 !important;}
.dataselectiontable tbody tr td input{margin-right: 5px !important;}
`