import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { setValue, getValue, displayMessage } from '../../Utils/helper.js'
import moment from 'moment';
import { IterativeRadioList } from '../index'
import { granularityList } from '../../Utils/staticData.js';
import { device } from '../../Utils/device.js';
import { StyledEngineProvider } from '@mui/material/styles';
import DataGridModalComponent from './DataGridModalComponent.js';
import ListGroup from 'react-bootstrap/ListGroup';
import { datagridColumns, datagridColumns2 
  , dgrDealers,dgrFOC,dgrED,dgrX1 , DataFrequency
,getForecastPeriods } from '../../Utils/staticData.js';
import Select from 'react-select';


function SectionB() {

  const [selectedComponent, setSelectedComponent] = useState(null);
  const [selDataFrequency, setSelDataFrequency] = useState(null);
  const [selForecastFrequency, setSelForecastFrequency] = useState(null);
  const [disabledGranularity, setDisabledGranularity] = useState([]);
  const [defaultGranularity, setDefaultGranularity] = useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [dgObj, setDgObj] = useState(null);
 
  

  const handleSelect = (component) => {
    setSelectedComponent(component);
    let objData = {
      weeks : datagridColumns2,
      storageName :component,
      rows : [],
      data : []
    }
    switch (component) {
      case 'DealerPromotions':
        objData.Name = 'Dealer Promotions';
        objData.rows = dgrDealers;
        objData.data = [];
        objData.weeks = datagridColumns;
        break;
      case 'FOC':
        objData.Name = 'FOC';
        objData.rows = dgrFOC;
        objData.data = [];
         break;
      case 'ED':
        objData.Name = 'ED';
        objData.rows = dgrED;
        objData.data = [];
         break;
      case 'X1':
        objData.Name = 'X1';
        objData.rows = dgrX1;
        objData.data = [];
         break;
      case 'X2':
        break;
      default:
        break;
    }
    setDgObj(objData);
    setModalShow(true);
 
  };

  const beginDateRef = useRef();
  const endDateRef = useRef();
  const cutOffDateRef = useRef();

  const dataAvail = { from: getDateAvailability('from'), to: getDateAvailability('to') };



  function getDateAvailability(str) {
    let dataAvail = getValue('local', 'masterdata', 'data_availability');
    return moment(dataAvail?.[str], 'DD-MM-YYYY').format('YYYY-MM-DD');
  }



  useEffect(() => {
    let formStr = sessionStorage.getItem('form');
    if (formStr) {
      let form = JSON.parse(formStr)
      let granItem = document.querySelector(`input[id='${form.granularity}']`);
      if (granItem) granItem.checked = true;
      //granularityRef.current.value = form.workflow_name;
      // beginDateRef.current.value =form.start_date; 

      beginDateRef.current.value = moment(form.start_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
      cutOffDateRef.current.value = moment(form.cut_off_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
      endDateRef.current.value = moment(form.end_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
    } else {
      console.log('session data not available')
    }
  })
  function fundamentalDateValidation(htmlElement, dateName) {
    let date = moment(htmlElement.value, 'YYYY-MM-DD');
    if (date.isValid()) {
      let beginDate = date;
      let fromDate = moment(dataAvail.from, 'YYYY-MM-DD')
      let toDate = moment(dataAvail.to, 'YYYY-MM-DD')
      let diff = beginDate.diff(fromDate, 'd');
      let diff2 = toDate.diff(beginDate, 'd');
      if (diff < 0 || diff2 < 0) {
        htmlElement.classList.add('error-text');
        displayMessage("danger", `Invalid ${dateName} Date`, `${dateName} date should be within the range of data availability`);
        return false;
      } else {
        htmlElement.classList.remove('error-text');
        return true;
      }
    } else {
      htmlElement.classList.add('error-text');
      displayMessage("danger", "Invalid Date", `${dateName} Date is invalid`);
      return false;
    }
  }

  const txtBeginDateOnBlurHandler = (e) => {
    let flag = fundamentalDateValidation(e.target, 'Begin');
    if (flag) {
      setValue('session', 'form', "start_date", moment(e.target.value).format('DD-MM-YYYY'));
    }
  }

  const txtEndDateOnBlurHandler = (e) => {
    let flag = fundamentalDateValidation(e.target, 'End');
    if (flag) {
      setValue('session', 'form', "end_date", moment(e.target.value).format('DD-MM-YYYY'));
    }
  }

  const txtCutOffDateOnBlurHandler = (e) => {
    let flag = fundamentalDateValidation(e.target, 'Cut Off');
    if (flag) {
      setValue('session', 'form', "cut_off_date", moment(e.target.value).format('DD-MM-YYYY'));
    }
  }

  const rdGranularityOnChangeHandler = (e) => {
    setValue('session', 'form', "granularity", e.target.id);
    setSelForecastFrequency(e.target.id);
  }

  const handleChangeDataFrequency = (selected) => {
    setSelDataFrequency(selected.value); 
    let result =   DataFrequency.find(elem => elem.value == selected.value).disabledValues;
    setDisabledGranularity(result);
    let defaultGranValue = selected.value.toLowerCase() === 'transaction' ? 'Weekly' : selected.value;
    setDefaultGranularity(defaultGranValue);
    setSelForecastFrequency(defaultGranValue);
  }

  const handleChangeForecastPeriod = (selected) => {
   // console.log(selected.value);
  }


  return (
    <Wrapper>
      <div id="notice" className="control-section date-notice">
        * Data available for forecast is between {moment(dataAvail.from, 'YYYY-MM-DD').format('DD-MM-YYYY')} to {moment(dataAvail.to, 'YYYY-MM-DD').format('DD-MM-YYYY')}
      </div>

      <div className='control-section'>
      <label className='mandatory lbl-for-tb'>Data Frequency</label>
      <div className="drp">
                <Select
                  key="SKU"
                  options={DataFrequency}
                  onChange={handleChangeDataFrequency} 
                  // styles={} 
                />
              </div>
      </div>

      <div className='control-section dates-section' >
        <div>
          <label className='mandatory lbl-for-tb'>Begin Training </label>
          <input ref={beginDateRef} type="date" name="beginDate"
            onBlur={txtBeginDateOnBlurHandler}
            min={dataAvail.from}
            max={dataAvail.to} />
        </div>
        <div>
          <label className='mandatory lbl-for-tb'>Train Until</label>
          <input ref={cutOffDateRef} type="date" name="cutOffDate" onBlur={txtCutOffDateOnBlurHandler}
            min={dataAvail.from}
            max={dataAvail.to} />
        </div>
        <div style={ { visibility:'hidden' }} >
          <label className='mandatory lbl-for-tb'>Forecast Until</label>
          <input ref={endDateRef} type="date" name="endDate" onBlur={txtEndDateOnBlurHandler}
            min={dataAvail.from}
            max={dataAvail.to} />
        </div>
      </div>




      <div className='control-section'>
        <label className='mandatory lbl-for-tb'>Forecast frequency</label>
        <IterativeRadioList ListName='granularity' IterativeData={granularityList}  DefaultValue={defaultGranularity} DisabledValues={disabledGranularity} OnSelectChange={rdGranularityOnChangeHandler} />
      </div>

      <div className='control-section'>
      <label className='mandatory lbl-for-tb'>Forecast Period</label>
      <div className="drp">
                <Select
                  key="forecastPeriod"
                  options={getForecastPeriods(selForecastFrequency)}
                  onChange={handleChangeForecastPeriod} 
                  // styles={} 
                />
      </div>
      </div>

      <div className='control-section'>
        <StyledEngineProvider injectFirst>
          <ListGroup className='list-group' >
            <ListGroup.Item onClick={() => handleSelect('DealerPromotions')} >Dealer Promotions</ListGroup.Item>
            <ListGroup.Item onClick={() => handleSelect('FOC')} >FOC</ListGroup.Item>
            <ListGroup.Item onClick={() => handleSelect('ED')} >ED</ListGroup.Item>
            <ListGroup.Item onClick={() => handleSelect('X1')} >X1</ListGroup.Item>
            <ListGroup.Item onClick={() => handleSelect('X2')} >X2</ListGroup.Item>
          </ListGroup>

        </StyledEngineProvider>
      </div>
      {modalShow && dgObj && (
        <DataGridModalComponent
          show={modalShow}
          onHide={() => setModalShow(false)}
          dgObj={dgObj}
        />)
        }
    </Wrapper>
  )
}

export default SectionB;

const Wrapper = styled.div`
display:flex;
flex-direction:column;


.list-group{
  width:fit-content;
  cursor:pointer;
  font-size:14px;
  div{
    outline: 1px solid; /* use instead of border */
    margin-top: 1px;
    margin-left: 1px;
    &:hover{
    background: var(--clr-primary-5);
  }
  }
}


.dates-section , .gran {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dates-section {
    margin-bottom: 1rem;
 
  
    div {
        display: flex;
        flex-direction: column;  
        justify-content: flex-start;
        color: var(--clr-primary-3);
        input[type='date'] {
          width: 13rem;
        }
    }
}
.date-notice{
  text-align: left;
    color: var(--clr-primary-1);
    font-style: italic;
    font-size: 14px;
}

.gran {
    border-radius : var(--radius);
    div {   
      width:100%;   
        label {
            width:100%;   
            color: black;
            font-style:normal;
            background: var(--clr-primary-9);
            border: 1px solid transparent;
            border-radius: 2px;
            display: inline-block;
            height: fit-content;
            text-align:center;
            
            line-height: 26px;
            margin: 2px;
            padding: 5px;
            transition: .2s all ease-in-out;
            cursor:pointer;
        }
        input[type="radio"] {
        display: none;
        }
        input[type="radio"]:checked + label {
            background: var(--clr-primary-5);
            border: 1px solid $form-border-color;
        }
    }
}

@media screen and  (${device.tablet}) {
  .dates-section , .gran {
    display: flex;
    flex-direction: column;
    row-gap :1.5rem;    

}
input[type='date'] {
          width: 90vw !important;
        }
}

`