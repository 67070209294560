import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Select from 'react-select';
import MultiSelectAll from './MultiSelectAll';
import useFetchData from '../../services/useFetchData'
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ApiEnums, getRequest, postRequest } from '../../services/DataRequestService'
import { displayMessage } from '../../Utils/helper';
// import SKUList from '../../Utils/SampleSKU.json'

function MappingSPtoSKUs() {

    const [selectedSKUs, setSelectedSKUs] = useState([]);
    const [selectedSP, setSelectedSP] = useState(null);
    const [assignedSkus, setAssignedSkus] = useState([]);

    useEffect(()=>{
        fetchAndBindAssignedSkus();
    },[])

    const apiUrl1 = `${process.env.REACT_APP_API_BASE_URL_2}${ApiEnums.get_sp}`;
    const apiUrl2 = `${process.env.REACT_APP_API_BASE_URL_2}${ApiEnums.get_skus}`;
    const apiUrl_assignSkus = `${process.env.REACT_APP_API_BASE_URL_2}${ApiEnums.assign_skus}`;
    const apiUrl_assignedSkus = `${process.env.REACT_APP_API_BASE_URL_2}${ApiEnums.assigned_skus}`;

    // get_sp API call with specific parameters
    const { data: data1, loading: loading1, error: error1 } = useFetchData(apiUrl1);

    // get_sku API call with different parameters
    const { data: data2, loading: loading2, error: error2 } = useFetchData(apiUrl2);

    // Handle loading and error states
    if (loading1 || loading2) return <div>Loading...</div>;
    if (error1) return <div>Error fetching Sales Persons List: {error1.message}</div>;
    if (error2) return <div>Error fetching SKU List: {error2.message}</div>;

    function formatSPData(data) {
        let result = data?.map(item => ({ label: item.sp_name, value: item.sp_id }));
        return result;
    }

    function formatSKUData(data) {
        let result = data?.map(item => ({ label: item.sku_name, value: item.sku_id }));
        return result.sort((a, b) => a.label.localeCompare(b.label));
    }

    const handleSPSelectChange = (option) => {
        setSelectedSP(option);
    }

    const handleAssignSkus = async () => {
  
        let requestObject = {
            url: apiUrl_assignSkus,
            config: {
                params: {
                    sp: selectedSP.label
                }
            },
            data: {
                sku_names: selectedSKUs.map(item => item.label)
            }
        }
        const responseData = await postRequest(requestObject);

        if (responseData?.status === 'success') {
            displayMessage("success", "Assignment Successful ", `Assignment Successful`);
        } else {
            displayMessage("danger", "Assignment Failed ", `Assignment Failed`);
        }

        fetchAndBindAssignedSkus();
    }

    async function fetchAndBindAssignedSkus(){
         // to display skus
         let requestObject2 = {
            url: apiUrl_assignedSkus
        }
        const responseData2 = await getRequest(requestObject2);

        if (responseData2?.status === 'success') {
            setAssignedSkus(responseData2?.data);
        } else {
            console.log(responseData2);
        }
    }

    return (
        <Wrapper>

        
                <div className="outer_container">
                   
                   
                    <FormContainer>
                        <Row className="mb-3">
                            <Col>
                                <Label className="lbltext" htmlFor="sales-persons">Sales Persons</Label>
                            </Col>
                            <Col>
                                <div className="select-control">
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        // defaultValue={{ label : data1?.data[0].name, value : data1?.data[0].id}}
                                        isDisabled={false}
                                        isClearable={false}
                                        isSearchable={true}
                                        name="sales-person"
                                        options={formatSPData(data1.data)}
                                        onChange={handleSPSelectChange}
                                    />
                                </div>

                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <Label className="lbltext" htmlFor="skus">SKUs</Label>
                            </Col>
                            <Col>
                                <div className="select-control multiselect-control">
                                    <MultiSelectAll
                                        options={formatSKUData(data2.data)}
                                        selectedOptions={selectedSKUs}
                                        setSelectedOptions={setSelectedSKUs} />

                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Button onClick={handleAssignSkus}>Assign</Button>
                            </Col>
                        </Row>
                    </FormContainer>
                  
                  { assignedSkus.sort((a, b) => a.sp_name.localeCompare(b.sp_name))?.length > 0 &&  <FormContainer>
                        <h4> Assigned SKUs</h4>
                    <Table striped >
                        <thead>
                            <tr>
                                <th>Sales Person</th>
                                <th>SKU</th>
                            </tr>
                        </thead>
                        <tbody>
                        {assignedSkus.map((item, index) => (
                            <tr key={index} className="my-2">
                                <td xs={6}>
                                    {item.sp_name}
                                </td>
                                <td xs={6}>
                                    {item.sku_name}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    </FormContainer>}
                    </div>
           
     
        </Wrapper>
    )
}

export default MappingSPtoSKUs

const Wrapper = styled.div`
 display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    .lbltext{
    text-align:left;
}

.select-control{
    font-size:16px;
    font-family:Quicksand, Arial, sans-serif !important;
    text-align:left;
    border:0px;
    outline:none;
}
.multiselect-control{
    button{
        width:100%;
    }
}

tr th {
    text-align:center;
}

.outer_container{
  display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height:100%;
    width:100%;
}


`;



const Header = styled.h1`
    font-size: 24px;
    color: #343a40; /* Darker text color */
    margin-bottom: 20px;
    text-align: center;
`;

const FormContainer = styled.div`
    background-color: #ffffff; /* White background for the form */
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    padding: 30px;
    height:450px;
    width:100%;

`;

const FormGroup = styled.div`
    margin-bottom: 20px; /* Space between form groups */
`;

const Label = styled.label`
    display: block;
    font-weight: bold;
    margin-bottom: 8px; /* Space between label and select input */
    color: #495057; /* Slightly lighter color than the header */
`;

const LoadingMessage = styled.div`
    font-size: 18px;
    color: #007bff; /* Bootstrap primary color */
`;

const ErrorMessage = styled.div`
    font-size: 18px;
    color: #dc3545; /* Bootstrap danger color */
`;

const Button = styled.button`
    background-color: #007bff; /* Bootstrap primary color */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3; /* Darker shade on hover */
    }

    &:focus {
        outline: none; /* Remove default outline */
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5); /* Outline effect on focus */
    }
`