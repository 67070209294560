import React, { useState } from 'react';
import { useTable } from 'react-table';
import styled from 'styled-components'


const MetricsTableComponent = ({ columns, data }) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const tableInstance = useTable({ columns, data });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  const handleCheckboxChange = (row) => {
    setSelectedRows((prev) => {
      if (prev.includes(row)) {
        return prev.filter((r) => r !== row);
      } else {
        return [...prev, row];
      }
    });
  };

  return (
    <Wrapper>
      <table {...getTableProps()}>
        <thead className='cust-thead'>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
              <th>Select</th>
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}  className='cust-tbody'>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
             
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
                   <td>
                  <input
                    type="checkbox"
                    onChange={() => handleCheckboxChange(row.original)}
                    checked={selectedRows.includes(row.original)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {/* <div>
        <h3>Selected Rows:</h3>
        <pre>{JSON.stringify(selectedRows, null, 2)}</pre>
      </div> */}
    </Wrapper>
  );
}

export default MetricsTableComponent;

const Wrapper = styled.div`
.cust-thead{
    position: sticky;
    top: 0;
}
`