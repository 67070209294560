import React, { useState } from 'react'
import styled from 'styled-components'
import { postRequest, ApiEnums , getRequest } from '../../services/DataRequestService'
import useFetchData from '../../services/useFetchData'
import { displayMessage , getValue } from '../../Utils/helper'
import { MultiSelect } from "react-multi-select-component";
import TableMatrix from './TableMatrix'
import Spinner2 from '../common/Spinner2'


export default function ConfigSection2() {



  const [selectedSP, setSelectedSP] = useState([]);


  
  const apiUrl1 = `${process.env.REACT_APP_API_BASE_URL_2}${ApiEnums.get_sp}`;

  // get_sp API call with specific parameters
  const { data: data1, loading: loading1, error: error1 } = useFetchData(apiUrl1);
  if (loading1) return <Spinner2 />;
  if (error1) return <div>Error fetching Sales Persons List: {error1.message}</div>;


  function formatSPData(data) {
    let result = data?.map(item => ({ label: item.sp_name, value: item.sp_id }));
    return result;
  }

  const handleSPSelectChange = (option) => {
    setSelectedSP(option);
    console.log(option)
  }

  //_assumption: workflow_id : 101 : this is given hardcoded default value for demo purpose. later it needs to be implemented  
  const handleSend = async () => {
 
    if (selectedSP?.length === 0){
      displayMessage('danger','Validation','Please choose Sales Person');
      return;
    }
    else{
      const workflowId = getValue('session','form','workflow_id');
      selectedSP.map(sp => sendEmail(sp.label,workflowId));
    }
  }

  const sendEmail = async (spname, workflowid) => {
    let requestObject = {
      url: ApiEnums.send_email_with_skus,
      config: {
        params: {
          workflow_id : workflowid,
          sp_name  : spname
        }
      }
    }
    const responseData = await postRequest(requestObject);
    console.log(responseData);
    if(responseData.status === 'success'){
      displayMessage('success',`Email  to ${spname}`,`Sent Successfully`);
      return;
    }
    else{
      displayMessage('danger','Email','Failed to send email');
      return;
    }
  }

  const handleSendEmail = async () => {
    // to display skus
    let requestObject2 = {
      url: ApiEnums.send_skus_emails
    }
    const responseData2 = await postRequest(requestObject2);

    if (responseData2?.status === 'success') {
      displayMessage('success', responseData2?.message, responseData2?.message)
    } else {
      displayMessage('danger', 'Email sending failed', 'For details check console')
      console.log(responseData2);
    }
  }


  return (
    <Wrapper>

      <div className="container">
        <div className="section-sp" >
        <label  className='lbl-for-tb'  htmlFor="sales-person">Workflow Name : </label>
        <label> { getValue('session','form','workflow_name')} </label>
        </div>
        <TableMatrix workflowID={getValue('session','form','workflow_id')} />
         
   

        <div className="section-sp">
          <label className='lbl-for-tb' htmlFor="sales-person">Sales Person : </label>
          <div className="input-control">
          <MultiSelect   
            key="sales-person"
            name="sales-person"
            options={formatSPData(data1.data)}
            onChange={handleSPSelectChange}
            value={selectedSP}
            disableSearch={true}
            className="multi-select" // Custom className
            
          />
          </div>
          
        </div>
        <div className="btn-section">
          <button className='btn btn-primary' onClick={handleSend} > Send </button>

        </div>
      </div>

    </Wrapper>
  )
}

const Wrapper = styled.div`

.container {
  width: 100%;
  padding: 0 10px;
  margin: 0 auto;
  margin-top: 10px;
  max-width:100% !important;
}


.input-control{
    width:300px;
    text-align:left !important;
    align-items:normal;
  }

table{
  width:50% !important;
  thead{
    th{
  padding: 8px  !important;
  text-align:center !important;
} 
  }
  tbody{
    tr{
      padding: 0px  !important;
      text-align:center !important;
    }
  }

}

.my-custom-multi-select{
   text-align:left;
   align-items:center;
}

.my-custom-dropdown {
  background-color: #f0f0f0;
  border: 2px solid red;
}

  table tbody tr:hover{
    background:none !important;
  }

       /* Hide default spin buttons for number input */
       input[type="number"] {
        -moz-appearance: textfield; /* Firefox */
        -webkit-appearance: none; /* Chrome, Safari, and Opera */
        appearance: none; /* Standard syntax */
        padding: 10px; /* Optional: padding for better appearance */
        width: 100px; /* Set width as needed */
        border: 1px solid #ccc; /* Border style */
        border-radius: 4px; /* Optional: border radius */
    }

    /* Hide the scrollbar (if present) */
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none; /* Removes the spin buttons */
        margin: 0; /* Removes any margin */
    }

    input[type="number"]{
      border-radius:5px;
      padding:0px 5px;
    }


input:focus {
  outline: 1px solid #ccc;
  border: 1px solid #ccc;
}

.btn-section{
  padding:5px;
  margin-top:2rem;
}

.section-a{
  margin-bottom:1rem;
  margin-right:10px;
  padding:1px;
  float:right;
}

.section-sp{
  margin:10px 0px;
  padding:10px 0px;
  width:20rem;
  display:flex;
}
  
  label{
    width:10rem;
    vertical-align:middle;
    padding-top:5px;
    padding-left:10px;
  }

  .section-wf{
  margin:10px 0px;
  padding:10px 0px;
  width:20rem;
  display:flex;
  label{
    width:fit-content;
    vertical-align:middle;
    padding-top:5px;
    padding-left:10px;
  }
}




`