import React, { useRef } from 'react'
import styled from 'styled-components'
import FilterItem from './FilterItem';
import '../../assets/css/button.css'
import { SelectedFilters } from '../../components'
import filterEnums from '../../Utils/enums';
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

function Filters(props) {
  const { masterData, wfData , closeHandler } = props;

  

  return (
    <Wrapper>
      <div id="divWfConfig" className='container'>
        <div className="close" onClick={closeHandler} id="btnClose" >
        <FontAwesomeIcon icon={faClose} size="lg" fixedWidth />
        </div>
        <FilterItem name="Workflow Configurations" type="header"></FilterItem>
        <div id="filters" className="scroller">
          <div id="divSelectedFilters" >
            <FilterItem name="Dates" type="filterSelected">
              <ul className='filter-item' >
                <li>
                  <label>Begin Date : {moment(wfData.start_date, 'DD-MM-YYYY').format('Do MMM YYYY')}</label>
                </li>
                <li>
                  <label>CutOff Date : {moment(wfData.cut_off_date, 'DD-MM-YYYY').format('Do MMM YYYY')}</label>
                </li>
                <li>
                  <label>End Date : {moment(wfData.end_date, 'DD-MM-YYYY').format('Do MMM YYYY')}</label>
                </li>
              </ul>
            </FilterItem>

            <FilterItem name="Granularity" type="filterSelected">
              <ul className='filter-item' >
                <li>
                  <label className='gran' >{wfData.granularity}</label>
                </li>
              </ul>
            </FilterItem>

            <FilterItem name="Geography" type="filterSelected">
              <SelectedFilters selectedData={wfData.states} eNumObject={filterEnums.states} mObject={masterData.states} />
            </FilterItem>

            <FilterItem name="Dealers" type="filterSelected" >
              <SelectedFilters selectedData={wfData.stores} eNumObject={filterEnums.stores} mObject={masterData.stores} />
            </FilterItem>

            <FilterItem name="Categories" type="filterSelected">
              <SelectedFilters selectedData={wfData.categories} eNumObject={filterEnums.categories} mObject={masterData.categories} />
            </FilterItem>

            <FilterItem name="Sub Categories" type="filterSelected">
              <SelectedFilters selectedData={wfData.departments} eNumObject={filterEnums.departments} mObject={masterData.departments} />
            </FilterItem>

            <FilterItem name="Items" type="filterSelected">
              <SelectedFilters selectedData={wfData.products} eNumObject={filterEnums.products} mObject={masterData.products} />
            </FilterItem>

            <FilterItem name="Models" type="filterSelected">
                <ul>
                  {
                    wfData?.models.map((model,index) => (<li key={index}>{model}</li>))
                  }
                </ul>
            </FilterItem>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`

.container{
  height : calc(100% - 5px);
  width : 20vw;
  border:1px solid #e4e7ed;
  margin : 5px;
  padding-right : 0px;
  padding-left : 0px;
  background : white;
  box-shadow:0 1px 3px -1px rgba(0,0,0,.4);
  -webkit-user-select: none;
  user-select: none;
  overflow:auto;
  position:relative;
}

.close{
  position:absolute;
  top:5px;
  right:10px;
  display:block;
  cursor: pointer;
  color:var(--clr-primary-1);
  :hover{
    color:var(--clr-primary-3);
  }
}

#divWfConfig{
  transition : width 0.5s;
  text-wrap: nowrap;
}



.scrolle-r{
  height : 70vh;
  overflow-y:scroll;
}

.filter-item {
  font-size:16px;
  color: #36454F;
  padding-left:0.3rem;
  li {
      margin: 10px;
      input[type="checkbox"],input[type="radio"]{
          margin-right :5px;
          }   
      };
  margin: 5px 0px;
}

.gran{
  text-transform:capitalize;
  font-size:16px;
}

`
export default Filters;

