import React, { useState, useEffect } from 'react';
import { ApiEnums } from '../../services/DataRequestService';
import axios from 'axios'; // Import axios for API calls

const TableMatrix = ({workflowID}) => {
  const [TableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  const apiUrl = `${process.env.REACT_APP_API_BASE_URL_2}${ApiEnums.weekly_workflow_data}`;

  // Fetch data using axios in useEffect
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make the API request
       
        const response = await axios.get(apiUrl, { params : { 'workflow_id' : workflowID } });
        setTableData(response.data.data); // Set the fetched data
        setLoading(false); // Turn off loading once data is fetched
      } catch (err) {
        setError(err); // Capture any errors
        setLoading(false); // Turn off loading in case of error
      }
    };

    fetchData(); // Call the async function to fetch data
  }, [apiUrl]); // Dependency array includes apiUrl

  // Handle loading and error states
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error fetching data: {error.message}</div>;

  // Check if TableData exists and is not empty
  if (!TableData || TableData.length === 0) {
    return <p>No data available</p>;
  }else{
  // Get unique SKUs
  const skus = [...new Set(TableData?.map(item => item.sku))];

  // Get unique weeks
  const weeks = [...new Set(TableData?.map(item => item.week))].sort((a, b) => a - b);

  // Generate grid matrix
  const matrix = skus.map(sku => {
    return weeks.map(week => {
      const item = TableData?.find(d => d.sku === sku && d.week === week);
      return item ? item.f_value : '-'; // Default '-' if no f_value found for the week
    });
  });

  // Render the table
  return (
    <table id="tablematrix1" border="1" style={{ borderCollapse: 'collapse', width: 'fit-content' }}>
      <thead>
        <tr>
          <th>SKU</th>
          {weeks.map(week => (
            <th key={week}>Week {week}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {skus.map((sku, skuIndex) => (
          <tr key={sku}>
            <td>{sku}</td>
            {matrix[skuIndex].map((f_value, weekIndex) => (
              <td key={weekIndex}>{f_value}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
  }

};

export default TableMatrix;
