import React from 'react'
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import { device } from  '../../Utils/device'

function Home() {
  return (
    <Wrapper>
    <div className='section-center mainbox page-width'>
      <div className="boxes">
        <Card>
          <Card.Body>
            <Card.Title>Upload Forecast</Card.Title>
            <Card.Text>
            A salesperson needs to upload the SKU list on a monthly basis.
            </Card.Text>          
          </Card.Body>
          <div className="card-footer">
          <Link to="/UploadSKUs">
              <Button>Click here</Button>
            </Link>
          </div>
        </Card>     
      </div>
    </div>
  </Wrapper>

  )
}

const Wrapper = styled.div`

.card{
  width:18rem;
  height:15rem;
  transition: transform 250ms;
  
}

.card:hover{
  transform: translateY(-2px);
}

.card-body{
  border:none;
}

.card-footer{
  display:flex;
  justify-content:flex-end;
  background-color:var(--clr-grey-9);
}

.card{   
  .card-title {
  color: var(--clr-second-1);
 ;
}
.btn{
  background-color:var(--clr-second-1);
  border-color:var(--clr-second-1);
}
}

.card:hover{  
  .card-title {
  color: var(--clr-primary-5);
}
.btn{
  background-color:var(--clr-primary-5);
  border-color:var(--clr-primary-5);
}
}



.card-title {
  color: var(--clr-second-1);
}
.card-title:hover {
  color: var(--clr-primary-5);
}

.card-text{
  color:var(--clr-grey-3);
}

.btn:hover{
  background-color:var(--clr-primary-5);
}

.boxes{
  display:flex;
      flex-direction : row;
      div.card{
    margin-top:20px;
    margin-right:20px;
  }
    }

  @media screen and  (${device.tablet}) {
    .boxes{
      display:flex;
      flex-direction : column;
      div.card{
    margin-top:20px;
  }
    }
    .products {
      grid-template-columns: 25% 1fr;
      min-height: 78vh;
    }
  }

  @media screen and  (${device.mobileL}){
  .boxes {
  display : flex;
  flex-direction : column;
  justify-content :flex-start;
  column-gap:2rem;
  div.card{
    margin-top:20px;
  }
}
  }


`
export default Home