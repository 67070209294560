import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import GraphComponent from '../components/order/GraphComponent';
import TableComponent from '../components/order/TableComponent';
import Select from 'react-select';
import SKUList from '../Utils/SampleSKU.json';
import { getRequest, ApiEnums, postRequest } from '../services/DataRequestService';


function OrderResult() {

  const [skuOptions, setSkuOptions] = useState(SKUList);
  const [selectedSKU, setSelectedSKU] = useState(null);
  const [ReportData, setReportData] = useState(null);

  
  const dataSample = {
    "Forecast": [
      {
        "2024-06": 16436,
        "2024-07": 45019,
        "2024-08": 54441,
        "2024-09": 55740,
        "2024-10": 5464,
        "2024-11": 22659,
        "2024-12": 46437,
        "2024-13": 83015,
        "2024-14": 10520
      }
    ],
    "Opening Inventory": [
      {
        "2024-06": 105050,
        "2024-07": 88614,
        "2024-08": 43595,
        "2024-09": 55740,
        "2024-10": 5464,
        "2024-11": 22659,
        "2024-12": 46437,
        "2024-13": 83015,
        "2024-14": 10520
      }
    ],
    "Quantity to be ordered": [
      {
        "2024-06": 0,
        "2024-07": 0,
        "2024-08": 54441,
        "2024-09": 55740,
        "2024-10": 5464,
        "2024-11": 22659,
        "2024-12": 46437,
        "2024-13": 83015,
        "2024-14": 10520
      }
    ],
    "Closing Inventory": [
      {
        "2024-06": 88614,
        "2024-07": 43595,
        "2024-08": 54441,
        "2024-09": 55740,
        "2024-10": 5464,
        "2024-11": 22659,
        "2024-12": 46437,
        "2024-13": 83015,
        "2024-14": 10520
      }
    ]
  }

  const handleChangeSKU = async (selected) => {
    setSelectedSKU(selected ? selected.value : null);
    let requestObject = {
      url: ApiEnums.get_orderdetails,
      config: {
        params: {
          skus: selected.value
        }
      }
    }
    const responseData = await getRequest(requestObject);
    if (responseData?.message.toLowerCase() === 'details found') {
      setReportData(responseData.Values);
    }
    else {
      console.log("Data not found ");
    }

  }

  return (
    <Wrapper>
      <div className='section-center mainbox page-width'>
        <div className="flexbox1">
          <div className='control'>
            <label className='lbl-for-tb' >SKU Name : </label>
            <Select
              key="SKU"
              options={skuOptions}
              onChange={handleChangeSKU}
            />
          </div>
          { ReportData && <>
          <div className="table-container">
            <TableComponent data={ReportData} />
          </div>
          <div className="graph-container">
            <GraphComponent data={ReportData} />
          </div>
          </>
          }
        </div>
      </div>
    </Wrapper>
  )
}

export default OrderResult;

const Wrapper = styled.div`

.mainbox{
  height:100%;
  display : flex;
  flex-direction : row;
  justify-content :space-around;
  padding:0;
  margin-top :0px;
  padding-top:20px;
}

.flexbox1{
  display:flex;
  flex-direction:column;
  row-gap:10px;
  margin:0 auto;
}

.lbl-for-tb {
  display: block;
  font-size:14px;
  color:var(--clr-primary-3); 
  margin-bottom:0.25rem;
  text-align:left;
  font-weight:bold;
  margin: auto 5px;
}

.control{
  width : 500px;
  display:grid;
  grid-template-columns:auto 1fr;
}

`