import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components';
import {
  ContentPanel,
  SectionA,
  SectionA1,
  SectionB,
  SectionC,SectionC_Old,
  SectionPromotion,
  SectionD,
  SectionE,
  SectionF,
  MenuTabs,
  MenuTab
} from '../components/wfcreate';
import menuStyles from '../assets/css/menu-tabs.module.css'
import { v4 as uuidv4 } from 'uuid';
import UserService from '../services/UserService';
import { useOutletContext } from "react-router-dom";
import {
  getStateList,
  getStoreList,
  getCategoryList,
  getDepartmentList,
  getProductsList,
  getModelList
} from '../Utils/filterScripts';
import { getRequest, ApiEnums } from '../services/DataRequestService';
import { displayMessage } from '../Utils/helper';
import moment from 'moment';
import SampleMasterData from '../Utils/SampleMasterData.json'

const newFormCreate = () => {
  let id = 'wf_' + uuidv4();
  let form = {
    id: id,
    object: "WorkflowForm",
    workflow_name: "",
    workflow_description: "",
    start_date: "",
    cut_off_date: "",
    end_date: "",
    granularity: "",
    states: [],
    stores: [],
    categories: [],
    departments: [],
    products: [],
    models: [],
    combinations: [],
    userid: UserService.getUserID(),
    is_validated: false,
    email_id: UserService.getEmail(),
    full_name: UserService.getFullName()
  };
  sessionStorage.setItem("form", JSON.stringify(form));
}


function WFCreate() {

  const MenuTabsRef = useRef();
  const workflowNameRef = useRef();
  const workflowDescriptionRef = useRef();
  const { loadingBar } = useOutletContext();

  const MenuList = {
    Menu1: {
      Id: "Menu1",
      Name: "Initialize Workflow",
      Tagline: "Initiates the forecasting task by gathering essential details such as workflow name and description."
    },
    // Menu1a: {
    //   Id: "Menu1a",
    //   Name: "Data Ingestion",
    //   Tagline: "Data Ingestion via configured database or using file Upload"
    // },
    Menu2: {
      Id: "Menu2",
      Name: "Temporal Settings",
      Tagline: "Define the temporal parameters, including start and end dates for training, validation, and test periods. Also, select the forecast frequency (daily, weekly, etc.)."
    },
    // Menu3: {
    //   Id: "Menu3",
    //   Name: "Data Selection",
    //   Tagline: "Establishes the hierarchy for forecasting, enabling users to specify location (store_id, US state name) and product (category, dept, brand, etc.) preferences. Allows flexible combination and selection for model creation."
    // },
    Menu3a: {
      Id: "Menu3a",
      Name: "Data Selection",
      Tagline: "Establishes the hierarchy for forecasting, enabling users to specify location (store_id, US state name) and product (category, dept, brand, etc.) preferences. Allows flexible combination and selection for model creation."
    },
    Menu4: {
      Id: "Menu4",
      Name: "Data Availability",
      Tagline: "Assesses the availability of sufficient data for all created hierarchy combinations (location, product hierarchy combos) to make informed decisions on inclusion in the forecasting workflow."
    },
    Menu4a: {
      Id: "Menu4a",
      Name: "Promotions",
      Tagline: "Promotions"
    },
    Menu5: {
      Id: "Menu5",
      Name: "Model Selection",
      Tagline: "Provides a platform for users to choose from a variety of forecasting models (ARIMA, TBAT, NBEATS, LightGBM, XGBoost, CatBoost, Holt Winters, TSLM, etc.) based on their preferences and requirements."
    },
    Menu6: {
      Id: "Menu6",
      Name: "Review & Launch Workflow",
      Tagline: "Summarizes all user selections and decisions made throughout the workflow configuration process before finalizing and submitting the forecasting task."
    }
  }


  const [menuTabState, setMenuTabState] = useState({ activeTab: MenuTabsRef.current?.props.children[0].props.label });

  useEffect(() => {
    let formStr = sessionStorage.getItem('form');
    if (!formStr) {
      newFormCreate();
    }
  }, [])

  useEffect(() => {
    const func = async () => {
      var masterDataStr = localStorage.getItem("masterdata");
      if (!masterDataStr) {
        let masterDataLocalString = ''

        try {
          let requestObject = {
            url: ApiEnums.masterdata
          }
        //  const responseData = await getRequest(requestObject);
          const responseData = SampleMasterData;

          let _states = getStateList(responseData.state);
          let _stores = getStoreList(responseData.store, responseData.state);
          let _categories = getCategoryList(responseData.category);
          let _departments = getDepartmentList(responseData.department, responseData.category);
          let _products = getProductsList(responseData.item, responseData.department, responseData.category);
          let _models = getModelList();
          let masterDataLocalObject = {
            states: _states,
            stores: _stores,
            categories: _categories,
            departments: _departments,
            products: _products,
            models: _models,
            data_availability: { from: responseData.available_from, to: responseData.available_to }
          }
          masterDataLocalString = JSON.stringify(masterDataLocalObject);
          localStorage.setItem("masterdata", masterDataLocalString);
          masterDataStr = masterDataLocalString;
        } catch (err) {
          console.log(err)
        }

      }
      let masterData = JSON.parse(masterDataStr);
      return { masterData }
    }
    func();
  }, []);


  const changeMenuTab = (menutab) => {

    let form = JSON.parse(sessionStorage.getItem('form'));
    let step2IsValid = true;
    let step3IsValid = false;
    let begin_date = moment(form['start_date'], 'dd-MM-YYYY');
    let cutoff_date = moment(form['cut_off_date'], 'dd-MM-YYYY');
    let end_date = moment(form['end_date'], 'dd-MM-YYYY');
    if (!begin_date.isValid()) {
      step2IsValid = false;
    }
    if (!cutoff_date.isValid()) {
      step2IsValid = false;
    }
    if (!end_date.isValid()) {
      step2IsValid = false;
    }
    const diff = end_date.diff(begin_date, 'd');
    const diff2 = cutoff_date.diff(begin_date, 'd');
    const diff3 = end_date.diff(cutoff_date, 'd');
    if (diff < 0) {
      step2IsValid = false;
    }
    if (diff2 < 0) {
      step2IsValid = false;
    }
    if (diff3 < 0) {
      step2IsValid = false;
    }

    let states = form['states'];
    let categories = form['categories'];

    if ((states.length > 0 && states.some(ele => ele.checked == true))
      || (categories.length > 0 && categories.some(elem => elem.checked == true))
    ) {
      step3IsValid = true;
    }



    switch (menutab) {
      case 'menu1':
      case 'menu1a':
      case 'menu2':
      case 'menu3':
      case 'menu3a':
        setMenuTabState({ activeTab: menutab });
        break;
      case 'menu4a':
        setMenuTabState({ activeTab: 'disabled' });
        break;
      // case 'menu2' : 
      // if (form['workflow_name'] !== "" && form['workflow_description'] !== "") {
      //   setMenuTabState({ activeTab: menutab });
      // }else{
      //   displayMessage("danger", "Basic Details", "Insufficient Data");
      // }

      //     break;
      // case 'menu3' : 
      // if(step2IsValid){
      //   setMenuTabState({ activeTab: menutab });
      // }else{
      //   displayMessage("danger", "Timeline Configuration", "Insufficient Data");
      // }

      //     break;
      case 'menu4':
        //    if (step3IsValid && step2IsValid) {
        if (true) {
          setMenuTabState({ activeTab: menutab });
        }
        else {
          displayMessage("danger", "Business Criteria Configuration", "Insufficient Data");
        }
        break;
      case 'menu5':
        setMenuTabState({ activeTab: menutab });
        break;
      case 'menu6':
        setMenuTabState({ activeTab: menutab });
        break;
      default: break;

    }

  }

  return (<Wrapper>

    <MenuTabs name="menutab"
      ref={MenuTabsRef}
      changeHandler={changeMenuTab}
      tabState={menuTabState}
      style={menuStyles} >
      <MenuTab label="menu1" name={MenuList.Menu1.Name}>
        <ContentPanel heading={MenuList.Menu1.Name}
          tagline={MenuList.Menu1.Tagline}
          changeTab={changeMenuTab}
          next="menu2" >
          <SectionA ref={{ a: workflowNameRef, b: workflowDescriptionRef }}
          />
        </ContentPanel>
      </MenuTab>
      {/* <MenuTab label="menu1a" name={MenuList.Menu1a.Name}>
      <ContentPanel heading={MenuList.Menu1a.Name}
          tagline={MenuList.Menu1a.Tagline}
          changeTab={changeMenuTab}
          next="menu2" >
            <SectionA1 /> 
          </ContentPanel>
      </MenuTab> */}
      <MenuTab label="menu2" name={MenuList.Menu2.Name}>
        <ContentPanel heading={MenuList.Menu2.Name}
          tagline={MenuList.Menu2.Tagline}
          changeTab={changeMenuTab} next="menu3a"  >
          <SectionB />
        </ContentPanel>
      </MenuTab>
      {/* <MenuTab label="menu3" name={MenuList.Menu3.Name}>
        <ContentPanel heading={MenuList.Menu3.Name}
          tagline={MenuList.Menu3.Tagline}
          changeTab={changeMenuTab} next="menu4" >
          <SectionC data={'data'} />
        </ContentPanel>
      </MenuTab> */}

      <MenuTab label="menu3a" name={MenuList.Menu3a.Name}>
        <ContentPanel heading={MenuList.Menu3a.Name}
          tagline={MenuList.Menu3a.Tagline}
          changeTab={changeMenuTab} next="menu4" >
          <SectionC_Old data={'data'} />
        </ContentPanel>
      </MenuTab>

      <MenuTab label="menu4" name={MenuList.Menu4.Name} >
        <ContentPanel heading={MenuList.Menu4.Name}
          tagline={MenuList.Menu4.Tagline}
          changeTab={changeMenuTab} next="menu5" loadingBar={loadingBar} >
          <SectionD ></SectionD>
        </ContentPanel>
      </MenuTab>

      <MenuTab label="menu4a" name={MenuList.Menu4a.Name} >
        <ContentPanel heading={MenuList.Menu4a.Name}
          tagline={MenuList.Menu4a.Tagline}
          changeTab={changeMenuTab} next="menu5" loadingBar={loadingBar} >
          {/* <SectionPromotion ></SectionPromotion> */}
        </ContentPanel>
      </MenuTab>

      <MenuTab label="menu5" name={MenuList.Menu5.Name}>
        <ContentPanel heading={MenuList.Menu5.Name}
          tagline={MenuList.Menu5.Tagline}
          changeTab={changeMenuTab} next="menu6" >
          <SectionE />
        </ContentPanel>
      </MenuTab>
      <MenuTab label="menu6" name={MenuList.Menu6.Name}>
        <ContentPanel heading={MenuList.Menu6.Name}
          tagline={MenuList.Menu6.Tagline}
        >
          <SectionF
            sel={{ a: workflowNameRef, b: workflowDescriptionRef }}
            sl={workflowNameRef}
            changeTab={changeMenuTab}
            loadingBar={loadingBar}
          />
        </ContentPanel>
      </MenuTab>
    </MenuTabs>
  </Wrapper>)
}

export default WFCreate;
export { newFormCreate }

const Wrapper = styled.div`

background-color : var(--clr-grey-10);
height : 100%;
#menutab > .tab-buttons::before{

}
.btn-reset{
  display: none;
}

`;