import HttpService from '../services/HttpService'

const ApiEnums = {
  masterdata: "/masterdata",
  workflows_old: "/workflows",
  workflows: "/get_workflows",
  workflow: "/workflow",
  workflow_form: "/workflow_form",
  wf_combination_report: "/wf_combination_report",
  wf_name_check :'/wf_name_check',
  count_record :'/count_record',
  databaselist : '/databaselist',
  dataingestiondb : '/dataingestiondb',
  getsampledata : '/getsampledata',
  get_orderdetails : '/get_orderdetails',
  upload_exog_files : '/upload_exog_files',
  upload_planned_sku_values : '/upload_planned_sku_values',
  get_skus:'/get_skus',
  get_sp:'/get_sp',
  assigned_skus : '/assigned_skus',
  assign_skus : '/assign_skus',
  send_skus_emails : '/send_skus_emails',
  weekly_workflow_data : '/weekly_workflow_data',
  get_wf_id : '/get_wf_id',
  fetch_workflow_skus : '/fetch_workflow_skus',
  send_email_with_skus : '/send_email_with_skus',
  get_fp_data : '/get_fp_data',
  update_f_values : '/update_f_values',
  update_workflow:'/update_workflow'
}


async function postRequest(requestObject) {
  try{
    const axiosClient = HttpService.getAxiosClient();
    const responseObject = await axiosClient.post(requestObject?.url, requestObject?.data, requestObject?.config);
    if (responseObject?.status === 200 || responseObject?.status === 201) {
      return responseObject.data;
    }
    else {
      console.log('Error in DataRequestService.postRequest');
      console.log(responseObject)
      return { status : responseObject?.status, output : responseObject };
    }
  }catch(error){
    console.log(error);
    return { status : 'error', 
             output : error.message };
  } 
}

function getRequest(requestObject) {
  const axiosClient = HttpService.getAxiosClient();
  let responseObject = axiosClient.get(requestObject.url, requestObject.config)
    .then(response => responseObject = response.data)
    .catch(error => {
      console.log('Error in DataRequestService.getRequest');
      console.error(error);
      return responseObject = 'error';
    });

  return responseObject;
}

export { ApiEnums, postRequest, getRequest }