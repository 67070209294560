import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useOutletContext , useLocation } from "react-router-dom";
import { getRequest, ApiEnums } from '../services/DataRequestService';
import UserService from '../services/UserService'
import moment from 'moment';
import SampleWorkflowItems from '../Utils/SampleWorkflowItems.json'

function WorkflowList() {
  const [data, setData] = useState();
  const { loadingBar} = useOutletContext();
  const {state} = useLocation();


  useEffect(() => {
    async function a() {
      loadingBar.current.continuousStart();

      let requestObject = {
        url: ApiEnums.workflows,
        config: {
          headers: {
            "Content-Type": "application/json"
          },
          params: {
            user_id: UserService.getUserID()
          }
        }
      }
       const responseObject = await getRequest(requestObject); 
      //const responseObject = SampleWorkflowItems; 

    
      setData(responseObject.data);
      loadingBar.current.complete();
    };
    a();
  }, [])


  const prepDateFormat = (date) => {
    if(date === null){
      return '';
    }else{
      return moment(date, 'DD-MM-YYYY').format('Do MMM YYYY')
    }    
  }

  return (

    <Wrapper>
      <div className='section-center mainbox page-width'>
        <table className="workflowlist">
          <thead>
            <tr>
              <th scope="col">Workflow Name</th>
              <th scope="col">Workflow Description</th>
              <th scope="col">Approval Status</th>
              <th scope="col">Begin Date</th>             
              <th scope="col">CutOff Date</th>
              <th scope="col">End Date</th>
              <th scope="col">Granularity</th>
              <th scope="col">Process Date</th>
              <th scope="col">Status</th>
              <th scope="col">Forecast Report</th>
            </tr>
          </thead>
          <tbody>
            {
              (!data || typeof (data) == 'string') ?
                <></> :
                <>
                  {data.map((elem, index) => (<tr key={index} className={ state?.wfid == elem.workflow_id ? 'highlight' : ''} >
                    <td>{elem.workflow_name}</td>
                    <td>{elem.workflow_description}</td>
                    <td>{elem?.approval_status }</td>
                    <td>{prepDateFormat(elem?.begin_date)}</td>
                    <td>{prepDateFormat(elem?.cut_off_date) }</td>
                    <td>{prepDateFormat(elem?.end_date)}</td>
                    <td>{elem.granularity}</td>
                    <td>{prepDateFormat(elem?.process_date)}</td>
                    <td>{elem?.status}</td>
                    <td>
                      {elem?.status?.toLowerCase() === 'completed' ?
                       <Link to={`/wf/${elem.workflow_name}`} state={{ wfId: elem.workflow_id  }} >Details</Link>
                        : <></>}
                    </td>

                  </tr>))}
                </>

            }
          </tbody>
        </table>
        
      </div>
    </Wrapper>

  )
}
const Wrapper = styled.div`

.workflowlist{
  margin : 10px;
}

.mainbox{
  height:100%;
  display : flex;
  flex-direction : row;
  justify-content :space-around;
  padding:0;
  margin-top :0px;
}



.cust-table{
  margin-bottom:0px;
  tbody{
    font-size : 14px;
  }  
}

.highlight{
  font-weight:bold;
}

`

export default WorkflowList;