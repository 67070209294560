import React from 'react'
import styled from 'styled-components';
import loaderImage from '../../assets/loader.gif'

function Spinner2() {
  return (
    <Wrapper>
      <img src={loaderImage} alt="loading..." />
    </Wrapper>
  )
}

export default Spinner2;

const Wrapper = styled.div`
position:absolute;
z-index: 99;
    width:100vw;
    height:100vh;
    background:transparent;

  img{
    position:fixed;
    top:50vh;
    left:50vw;
    z-index: 100;
  }
`
