// this is used in SectionA1 of WFCreate 
import { TextField } from '@mui/material';
import { useState } from 'react';

const listDbType = [
    {label : "SQL", value:"SQL"},
    {label : "MySQL", value:"MySQL"},
    {label : "Postgres", value:"Postgres"},
    {label : "MongoDb", value:"MongoDb"}
]

const listDatabases = [
  {label : "database1",  value:"database1"},
  {label : "database2",  value:"database2"},
  {label : "database3",  value:"database3"},
  {label : "database4",  value:"database4"}
]

const listDataTables = [
  {label : "datatable1",  value:"datatable1"},
  {label : "datatable2",  value:"datatable2"},
  {label : "datatable3",  value:"datatable3"},
  {label : "datatable4",  value:"datatable4"}
]

// this is used in SectionA1 of WFCreate
const wfCreateFileUploadFormats = [
    {label : "csv", value:"csv"},
    {label : "xlsx", value:"xlsx"},
    {label : "perk", value:"perk"}
]

const granularityList =[
    { id: 'Daily', label: 'Daily' },
    { id: 'Weekly', label: 'Weekly' },
    { id: 'Monthly', label: 'Monthly' },
    { id: 'Quarterly', label: 'Quarterly' }
  ]

  const levelsList =[
    { value: 'Level1', label: 'Level1' },
    { value: 'Level2', label: 'Level2' },
    { value: 'Level3', label: 'Level3' },
    { value: 'Level4', label: 'Level4' },
    { value: 'Level5', label: 'Level5' },
    { value: 'Level6', label: 'Level6' },
    { value: 'Level7', label: 'Level7' },
    { value: 'Level8', label: 'Level8' },
    { value: 'Level9', label: 'Level9' },
    { value: 'Level10', label: 'Level10' },
    { value: 'Level11', label: 'Level11' },
    { value: 'Level12', label: 'Level12' },
  ]

const DataIngestType = [ { id: 'Database', label: 'Database' , checked : true },
{ id: 'FileUpload', label: 'File Upload' },
]


const levelAggregation = [
  {
    "level_id": "1",
    "level_description": "Unit sales of all products, aggregated for all stores/states",
    "no_of_series": "1",
    "values": " each row one series"
  },
  {
    "level_id": "2",
    "level_description": "Unit sales of all products, aggregated for each State",
    "no_of_series": "3",
    "values": "CA, TA, WI"
  },
  {
    "level_id": "3",
    "level_description": "Unit sales of all products, aggregated for each store ",
    "no_of_series": "10",
    "values": "CA_1, CA_2, CA_3, CA_4, TX_1, TX_2, TX_3, WI_1, WI_2, WI_3"
  },
  {
    "level_id": "4",
    "level_description": "Unit sales of all products, aggregated for each category",
    "no_of_series": "3",
    "values": "Hobbies, Foods, and Household"
  },
  {
    "level_id": "5",
    "level_description": "Unit sales of all products, aggregated for each department",
    "no_of_series": "7",
    "values": "FOOD_1, FOOD_2, FOOD_3, HOBBIES_1, HOBBIES_2, HOUSEHOLD_1, HOUSEHOLD_2"
  },
  {
    "level_id": "6",
    "level_description": "Unit sales of all products, aggregated for each State and category",
    "no_of_series": "9",
    "values": "HOBBIES_CA, HOBBIES_TX, HOBBIES_WI, ,FOODS_CA, FOODS_TX, FOODS_WI, HOUSEHOLD_CA, HOUSEHOLD_TX, HOUSEHOLD_WI"
  },
  {
    "level_id": "7",
    "level_description": "Unit sales of all products, aggregated for each State and department",
    "no_of_series": "21",
    "values": " (7 department X 3 State)"
  },
  {
    "level_id": "8",
    "level_description": "Unit sales of all products, aggregated for each store and category",
    "no_of_series": "30",
    "values": " (10 store X 3 Category)"
  },
  {
    "level_id": "9",
    "level_description": "Unit sales of all products, aggregated for each store and department",
    "no_of_series": "70",
    "values": " (10 store X 7 department)"
  },
  {
    "level_id": "10",
    "level_description": "Unit sales of product x, aggregated for all stores/states",
    "no_of_series": "3,049",
    "values": " CA_1(3049 records), CA_2(3049 records), CA_3(3049 stores), CA_4(3049 records), TX_1, TX_2, TX_3, WI_1, WI_2, WI_3(3049 records)"
  },
  {
    "level_id": "11",
    "level_description": "Unit sales of product x, aggregated for each State",
    "no_of_series": "9,147",
    "values": " (3049 records in a store X 3 states)"
  },
  {
    "level_id": "12",
    "level_description": "Unit sales of product x, aggregated for each store",
    "no_of_series": "30,490",
    "values": " 3049 records in a store X 10 stores)"
  }
]

const DataFrequency =[
  { value: 'Transaction', label: 'Transaction' , disabledValues:['Daily'] },
  { value: 'Daily', label: 'Daily' , disabledValues:[] },
  { value: 'Weekly', label: 'Weekly' , disabledValues:['Daily'] },
  { value: 'Monthly', label: 'Monthly' , disabledValues:['Daily','Weekly'] },
  { value: 'Quarterly', label: 'Quarterly' , disabledValues:['Daily','Weekly','Monthly'] }
]


const EditCellComponent = (props) => {
  const { id, field, value, api } = props;

  const handleChange = (event) => {
    const newValue = event.target.value;
    if (newValue === '' || newValue === '0' || newValue === '1') {
      api.setEditCellValue({ id, field, value: newValue });
    }else{
      api.setEditCellValue({ id, field, value: 0 });

    }
  };

  return (
    <TextField
      value={null}
      placeholder={'0 or 1'}
      onChange={handleChange}
      inputProps={{ maxLength: 2 }}
      autoFocus
    />
  );
};

// Custom edit component
const CustomEditComponent = (props) => {
  const { id, value, field, api } = props;
  const [inputValue, setInputValue] = useState(value);

  const handleChange = (event) => {
    const newValue = event.target.value;
    if (newValue.length <= 7) {
      setInputValue(newValue);
      api.setEditCellValue({ id, field, value: newValue }, event);
    }
  };

  return (
    <TextField
      value={inputValue}
      onChange={handleChange}
      fullWidth
    />
  );
};

// this datagridColumns is enabled to get only numerical value 0 and 1
const datagridColumns = [
  { field: 'id', headerName: 'Weeks', width: 250, cellClassName: 'first-field' },
  { field: 'w1', headerName: '2024-06', type: 'number', width: 80, editable: true , renderEditCell: (params) => <EditCellComponent {...params} /> },
  { field: 'w2', headerName: '2024-07', type: 'number', width: 80, editable: true , renderEditCell: (params) => <EditCellComponent {...params} /> },
  { field: 'w3', headerName: '2024-08', type: 'number', width: 80, editable: true , renderEditCell: (params) => <EditCellComponent {...params} /> },
  { field: 'w4', headerName: '2024-09', type: 'number', width: 80, editable: true , renderEditCell: (params) => <EditCellComponent {...params} /> },
  { field: 'w5', headerName: '2024-10', type: 'number', width: 80, editable: true , renderEditCell: (params) => <EditCellComponent {...params} /> },
  { field: 'w6', headerName: '2024-11', type: 'number', width: 80, editable: true , renderEditCell: (params) => <EditCellComponent {...params} /> },
  { field: 'w7', headerName: '2024-12', type: 'number', width: 80, editable: true , renderEditCell: (params) => <EditCellComponent {...params} /> },
  { field: 'w8', headerName: '2024-13', type: 'number', width: 80, editable: true , renderEditCell: (params) => <EditCellComponent {...params} /> },
  { field: 'w9', headerName: '2024-10', type: 'number', width: 80, editable: true , renderEditCell: (params) => <EditCellComponent {...params} /> },
  { field: 'w10', headerName: '2024-11', type: 'number', width:80, editable: true , renderEditCell: (params) => <EditCellComponent {...params} /> },
  { field: 'w11', headerName: '2024-12', type: 'number', width:80, editable: true , renderEditCell: (params) => <EditCellComponent {...params} /> },
  { field: 'w12', headerName: '2024-13', type: 'number', width:80, editable: true , renderEditCell: (params) => <EditCellComponent {...params} /> },
  { field: 'w13', headerName: '2024-12', type: 'number', width:80, editable: true , renderEditCell: (params) => <EditCellComponent {...params} /> },
  { field: 'w14', headerName: '2024-13', type: 'number', width:80, editable: true , renderEditCell: (params) => <EditCellComponent {...params} /> },
];

// this datagridColumns2 is enabled to get any numerical value
const datagridColumns2 = [
  { field: 'id', headerName: 'Weeks', width: 250, cellClassName: 'first-field' },
  { field: 'w1', headerName: '2024-06', type: 'number', width: 80, editable: true , renderEditCell: (params) => <CustomEditComponent {...params} />  },
  { field: 'w2', headerName: '2024-07', type: 'number', width: 80, editable: true , renderEditCell: (params) => <CustomEditComponent {...params} />  },
  { field: 'w3', headerName: '2024-08', type: 'number', width: 80, editable: true , renderEditCell: (params) => <CustomEditComponent {...params} />  },
  { field: 'w4', headerName: '2024-09', type: 'number', width: 80, editable: true , renderEditCell: (params) => <CustomEditComponent {...params} />  },
  { field: 'w5', headerName: '2024-10', type: 'number', width: 80, editable: true , renderEditCell: (params) => <CustomEditComponent {...params} />  },
  { field: 'w6', headerName: '2024-11', type: 'number', width: 80, editable: true , renderEditCell: (params) => <CustomEditComponent {...params} />  },
  { field: 'w7', headerName: '2024-12', type: 'number', width: 80, editable: true , renderEditCell: (params) => <CustomEditComponent {...params} />  },
  { field: 'w8', headerName: '2024-13', type: 'number', width: 80, editable: true , renderEditCell: (params) => <CustomEditComponent {...params} />  },
  { field: 'w9', headerName: '2024-10', type: 'number', width: 80, editable: true , renderEditCell: (params) => <CustomEditComponent {...params} />  },
  { field: 'w10', headerName: '2024-11', type: 'number', width:80, editable: true , renderEditCell: (params) => <CustomEditComponent {...params} />  },
  { field: 'w11', headerName: '2024-12', type: 'number', width:80, editable: true , renderEditCell: (params) => <CustomEditComponent {...params} />  },
  { field: 'w12', headerName: '2024-13', type: 'number', width:80, editable: true , renderEditCell: (params) => <CustomEditComponent {...params} />  },
  { field: 'w13', headerName: '2024-12', type: 'number', width:80, editable: true , renderEditCell: (params) => <CustomEditComponent {...params} />  },
  { field: 'w14', headerName: '2024-13', type: 'number', width:80, editable: true , renderEditCell: (params) => <CustomEditComponent {...params} />  },
];


const dgrDealers = ['Dealer1','Dealer2','Dealer3','Dealer4','Dealer5','Dealer6','Dealer7','Dealer8','Dealer9','Dealer10'];
const dgrFOC = ['Dealer1','Dealer2','Dealer3','Dealer4','Dealer5','Dealer6','Dealer7','Dealer8','Dealer9','Dealer10'];
const dgrED = ['Dealer1','Dealer2','Dealer3','Dealer4','Dealer5','Dealer6','Dealer7','Dealer8','Dealer9','Dealer10'];
const dgrX1 = ['Dealer1','Dealer2','Dealer3','Dealer4','Dealer5','Dealer6','Dealer7','Dealer8','Dealer9','Dealer10'];


const ForecastPeriods = [
  {
    frequency : 'Daily',
    periods : ['15 Days', '30 Days']
  },
  {
    frequency : 'Weekly',
    periods : [
      "For next 1 week",
      "For next 2 weeks",
      "For next 3 weeks",
      "For next 4 weeks",
      "For next 5 weeks",
      "For next 6 weeks",
      "For next 7 weeks",
      "For next 8 weeks",
      "For next 9 weeks",
      "For next 10 weeks",
      "For next 11 weeks",
      "For next 12 weeks",
      "For next 13 weeks",
      "For next 14 weeks",
      "For next 15 weeks",
      "For next 16 weeks",
      "For next 17 weeks",
      "For next 18 weeks",
      "For next 19 weeks",
      "For next 20 weeks",
      "For next 21 weeks",
      "For next 22 weeks",
      "For next 23 weeks",
      "For next 24 weeks",
      "For next 25 weeks",
      "For next 26 weeks",
      "For next 27 weeks",
      "For next 28 weeks",
      "For next 29 weeks",
      "For next 30 weeks",
      "For next 31 weeks",
      "For next 32 weeks",
      "For next 33 weeks",
      "For next 34 weeks",
      "For next 35 weeks",
      "For next 36 weeks",
      "For next 37 weeks",
      "For next 38 weeks",
      "For next 39 weeks",
      "For next 40 weeks",
      "For next 41 weeks",
      "For next 42 weeks",
      "For next 43 weeks",
      "For next 44 weeks",
      "For next 45 weeks",
      "For next 46 weeks",
      "For next 47 weeks",
      "For next 48 weeks",
      "For next 49 weeks",
      "For next 50 weeks",
      "For next 51 weeks",
      "For next 52 weeks"
  ]
  },
  {
    frequency : 'Monthly',
    periods : [
      "For next 1 month",
      "For next 2 months",
      "For next 3 months",
      "For next 4 months",
      "For next 5 months",
      "For next 6 months",
      "For next 7 months",
      "For next 8 months",
      "For next 9 months",
      "For next 10 months",
      "For next 11 months",
      "For next 12 months"
  ]
  },
  {
    frequency : 'Quarterly',
    periods :  [
      "For next 3 Quarters",
      "For next 6 Quarters",
      "For next 9 Quarters",
      "For next 12 Quarters"
  ]
  },
]

function getForecastPeriods(frequency){
  let result = ForecastPeriods.find(elem => elem.frequency ==  frequency)
                              ?.periods
                              ?.map(el => ({  label : el , value :el }));

  return result;    
}

export { listDbType , 
  wfCreateFileUploadFormats, 
  granularityList, 
  DataIngestType, 
  levelsList, 
  levelAggregation,
  listDatabases,
  listDataTables,
  DataFrequency,
  ForecastPeriods,
  getForecastPeriods,
  datagridColumns,
  datagridColumns2,
  dgrDealers,
  dgrFOC,
  dgrED,
  dgrX1
}