import React, { useState, useEffect } from 'react';
import { ApiEnums , postRequest } from '../../services/DataRequestService';
import axios from 'axios';
import styled from 'styled-components'
import { displayMessage } from '../../Utils/helper';
import UserService from '../../services/UserService';

const TableComponent = ({ WorkflowObject,setSelectedWF }) => {
  const [TableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [updatedValues, setUpdatedValues] = useState({}); // State to track updated f_values
  const [key, setKey] = useState(0);

  const apiUrl = `${process.env.REACT_APP_API_BASE_URL_2}${ApiEnums.get_fp_data}`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make the API request
        const response = await axios.get(apiUrl, { params: { 'workflow_name': WorkflowObject.name } });
        setTableData(response.data.data?.skus_data); // Set the fetched data
        setLoading(false); // Turn off loading once data is fetched
        setError(null);
      } catch (err) {
        console.log(err)
        setError(err); // Capture any errors
        setLoading(false); // Turn off loading in case of error
      }
    };

    fetchData(); // Call the async function to fetch data
  }, [WorkflowObject])
  
  // Handle loading and error states
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error fetching data: {error.message}</div>;

  const reloadComponent = () => {
    setKey(prevKey => prevKey + 1); // Change key to trigger re-render
  };
    // Function to handle input changes
    const handleInputChange = (sku, week, value) => {
      setUpdatedValues(prevValues => ({
        ...prevValues,
        [`${sku}_${week}`]: Number(value), // Store updated value for each SKU and week
      }));
    };
  
    // Function to handle submit and format data for posting
    const handleSubmit = async () => {
      const formattedData = TableData.map(item => ({
        ...item, // Spread the existing data
        f_value: updatedValues[`${item.sku}_${item.week}`] || item.f_value, // Use updated value or fallback to the original one
      }));
      //console.log(formattedData);

      await UploadData(WorkflowObject.id, WorkflowObject.name,formattedData)

    };

   async  function UploadData(wfid,wfname,payloaddata){
      let requestObject = {
        url: ApiEnums.update_f_values,
        config: {
          params: {
            user_id: UserService.getUserID()
          }
        },
        data: {
          workflow_id: wfid,
          workflow_name: wfname,
          skus_data:payloaddata
        }
      }
      const responseObject = await postRequest(requestObject);
      if(responseObject.status === "success"){
        displayMessage('success','Updated Successfully','Updated Successfully')
      }else{
        console.log(responseObject);
      }
      setSelectedWF({});
      setTimeout(reloadComponent(),3000);
    }


  // Check if TableData exists and is not empty
  if (!TableData || TableData.length === 0) {
    return <p>No data available</p>;
  } else {
   
    // Helper function to group data by SKU
    const groupBySKU = (TableData) => {
      const result = {};
      TableData.forEach(item => {
        if (!result[item.sku]) {
          result[item.sku] = {};
        }
        result[item.sku][item.week] = { f_value: item.f_value, p_value: item.p_value };
      });
      return result;
    };

    const groupedData = groupBySKU(TableData);

    // Extract unique weeks for the header
    const uniqueWeeks = [...new Set(TableData.map(item => item.week))].sort((a,b)=> a-b);
    return (
      <>
      <Table border="1" cellPadding="5" cellSpacing="0">
        <thead>
          <tr>
            <th rowSpan="2">SKU</th>
            {uniqueWeeks.map(week => (
              <th colSpan="2" key={week}>Week {week}</th>
            ))}
          </tr>
          <tr>
            {uniqueWeeks.map(week => (
              <React.Fragment key={week}>
                <th>Forecast</th>
                <th>Planned</th>
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(groupedData).map(sku => (
            <tr key={sku}>
              <td>{sku}</td>
              {uniqueWeeks.map(week => (
                <React.Fragment key={week}>
                  <td>
                    <input type="number"
                    onChange={(e) => handleInputChange(sku, week, e.target.value)}
                    defaultValue={groupedData[sku][week]?.f_value || 0} />
                  </td>
                 
                  <td className='inactive' >{groupedData[sku][week]?.p_value || 0}</td>
                </React.Fragment>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>  
      <div className="btn-section">
      <button className='btn btn-primary' onClick={handleSubmit}>Approve</button> {/* Button to handle submission */}
      </div>     
      </>   
    );
  }


};

export default TableComponent;

const Table = styled.table`
    /* font-family: Quicksand, Arial, sans-serif; */
    border-collapse: collapse;
    width: 100%;
    overflow-y: scroll;
    overflow-x:auto;
    font-size: 14px;

   
   tr {
    background: white !important;
  }

   tr > td > input {
    width :100% !important;
   }

   tr > td {
    text-align:center;
    padding:4px !important;
   }
  
   th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: var(--clr-primary-5);
    color: white;
  }

  td.inactive{
    background-color:#F5F5F5;
  }

  thead{
    th{
  padding: 8px  !important;
  text-align:center !important;
} 
  }
  tbody{
    tr{
      padding: 0px  !important;
    }
  }




   tbody tr:hover{
    background:none !important;
  }

       /* Hide default spin buttons for number input */
       input[type="number"] {
        -moz-appearance: textfield; /* Firefox */
        -webkit-appearance: none; /* Chrome, Safari, and Opera */
        appearance: none; /* Standard syntax */
        padding: 10px; /* Optional: padding for better appearance */
        width: 100px; /* Set width as needed */
        border: 1px solid #ccc; /* Border style */
        border-radius: 4px; /* Optional: border radius */
    }

    /* Hide the scrollbar (if present) */
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none; /* Removes the spin buttons */
        margin: 0; /* Removes any margin */
    }

    input[type="number"]{
      width:3rem;
      border-radius:5px;
      padding:0px 5px;
      text-align:center;
    }



input:focus {
  outline: 1px solid #ccc;
  border: 1px solid #ccc;
}
`