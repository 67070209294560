import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Compare from '../components/configurationsalesperson/Compare';
import {
    ContentPanel,
    ConfigSection2,
    MenuTab,
    MenuTabs,
    MappingSPtoSKUs
} from '../components/configurationsalesperson';
import menuStyles from '../assets/css/menu-tabs-2.module.css'

function ConfigurationSalesPerson() {
    const MenuTabsRef = useRef();
    const [menuTabState, setMenuTabState] = useState({ activeTab: 'menu1' });

    const MenuList = {
        Menu1: {
            Id: "Menu1",
            Name: "Mapping Sales Person to SKUs",
            Tagline: "Mapping Sales Person to SKUs"
        }
        // ,Menu2: {
        //     Id: "Menu2",
        //     Name: "View and Approve SKUs forecast values",
        //     Tagline: "View and Approve SKUs forecast values"
        // }
    }

    const changeMenuTab = (menutab) => {
        switch (menutab) {
            case 'menu1':
            case 'menu2':
                setMenuTabState({ activeTab: menutab });
                break;
            default: break;

        }
    }

    return (
        <Wrapper>

            {/* <MenuTabs name="menutab"
                ref={MenuTabsRef}
                changeHandler={changeMenuTab}
                tabState={menuTabState}
                style={menuStyles} >

                <MenuTab label="menu1" name={MenuList.Menu1.Name}>
                    <ContentPanel heading={MenuList.Menu1.Name}
                        tagline={MenuList.Menu1.Tagline}
                        changeTab={changeMenuTab}
                    >
                        <MappingSPtoSKUs />
                    </ContentPanel>
                </MenuTab>

               <MenuTab label="menu2" name={MenuList.Menu2.Name}>
                    <ContentPanel heading={MenuList.Menu2.Name}
                        tagline={MenuList.Menu2.Tagline}
                        changeTab={changeMenuTab}
                    >
                       <ConfigSection2 />
                    </ContentPanel>
                </MenuTab> 

            </MenuTabs> */}
            <Compare />
        </Wrapper>
    )
}

export default ConfigurationSalesPerson;

const Wrapper = styled.div`
height : 100%;
`;