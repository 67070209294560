import React, { useState, useRef } from 'react'
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import { getValue , displayMessage  } from '../../Utils/helper';
import {  ApiEnums } from '../../services/DataRequestService';
import UserService from '../../services/UserService';




function FileUploadComponent() {

  const fileInput1 = useRef(null);
  const fileInput2 = useRef(null);
  const fileInput3 = useRef(null);
  const fileInput4 = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const fileInputs = [fileInput1, fileInput2, fileInput3, fileInput4];

    const filesWithSheetNames = fileInputs.map(inputRef => ({
      file: inputRef.current.files[0],
      sheetName: inputRef.current.dataset.sheetname
    }));

    filesWithSheetNames.forEach((fileWithSheetName, index) => {
      const inputRef = fileInputs[index];
      if (!fileWithSheetName.file) {
        inputRef.current.style.border = '1px solid red';
        displayMessage('danger', 'Please choose the file to upload', `${fileWithSheetName.sheetName}`)

      } else {
        inputRef.current.style.border = ''; // Reset to default if the file is not null/undefined
      }
    });

    const endpoint = `${process.env.REACT_APP_API_BASE_URL}${ApiEnums.upload_exog_files}`;
    //const endpoint = 'http://localhost:9071/upload_exog_files';
    const userId = UserService.getUserID();
    const workflowId = getValue('session','form','id');

    const uploadFile = async ({ file, sheetName }) => {
      const formData = new FormData();
      formData.append('file', file, file.name);

      return await fetch(`${endpoint}?user_Id=${userId}&workflow_Id=${workflowId}&sheet_name=${sheetName}`, {
        method: 'POST',
        headers: {
          'accept': 'application/json',
        },
        body: formData,
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`Failed to upload ${file.name}`);
          }
          return response.json();
        });
    };

    try {
      const uploadPromises = filesWithSheetNames.map(uploadFile);

      const results = await Promise.all(uploadPromises);
      console.log('All files uploaded successfully:', results);
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  return ( 
    <Wrapper>
      <form onSubmit={handleSubmit}>
        <div className="fileupload-section">
          <div className="fileInput">
            <label className='lbl-for-tb' htmlFor="file1">Forecast model file</label>
            <input  ref={fileInput1} className='txtbox' type="file" id="file1" name="file1" accept=".xls,.xlsx,.csv"  data-sheetname='Forecast Sheet'  ></input>
          </div>
          <div className="fileInput">
            <label className='lbl-for-tb' htmlFor="file2">Inventory file</label>
            <input  ref={fileInput2} className='txtbox' type="file" id="file2" name="file2" accept=".xls,.xlsx,.csv"  data-sheetname='Inventory File'  ></input>
          </div>
          <div className="fileInput">
            <label className='lbl-for-tb' htmlFor="file2">EOQ file</label>
            <input  ref={fileInput3} className='txtbox' type="file" id="file3" name="file3" accept=".xls,.xlsx,.csv"  data-sheetname='EOQ file' ></input>
          </div>
          <div className="fileInput">
            <label className='lbl-for-tb' htmlFor="file2">Safety Stock</label>
            <input ref={fileInput4} className='txtbox' type="file" id="file4" name="file4" accept=".xls,.xlsx,.csv"   data-sheetname='Safety Stock' ></input>
          </div>
        </div>
        <Button type="submit">Upload Files</Button>
      </form>
    </Wrapper>
  )
}


export default FileUploadComponent;

const Wrapper = styled.div`
border : 1px solid black;
border-radius : 5px;
padding: 10px 0px 10px 10px; 
margin-bottom : 10px;

.fileupload-section{
  display:grid;
  grid-template-columns: 1fr 1fr;
  
}

input[type="file"] {
  width :400px;
}

div.fileInput{
  text-align:left;
  padding-bottom : 2rem;
}


`