import React, { useRef } from 'react';
import styled from 'styled-components';
import LoadingBar from 'react-top-loading-bar';
import {
  Outlet
} from 'react-router-dom'
import {ErrorBoundary} from './ErrorBoundary';
import { SiteBreadCrumb } from '../../components';
import { device } from '../../Utils/device';

function Page(props) {
  const loadingBarRef = useRef(null)
  return (
    <Wrapper>
      <ErrorBoundary>
        <LoadingBar color='#ED6026' ref={loadingBarRef} />
        <div className="section-center">
          <SiteBreadCrumb />
          <Outlet context={{ loadingBar: loadingBarRef }} />
        </div>
      </ErrorBoundary>
    </Wrapper>
  )
}

export default Page;


const Wrapper = styled.div`
  /* min-height: max(calc(100vh - 20vh),fit-content); */
  height:100%;
  min-height: 90vh;
 
  
  /* border-top: 1px solid var(--clr-primary-1); */
  margin-top:4.5rem;
  /* box-shadow: rgba(100,100,111,0.3) 0px 7px 29px 0px; */
  background-color:var(--clr-background);

  @media screen and (${device.tablet}) {
       
  }
  
  @media screen and (${device.mobileL}) {
    margin-top:8rem;
  }
`
