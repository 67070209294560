// useFetchData.js
import { useState, useEffect } from 'react';

const useFetchData = (url, params) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const queryParams = new URLSearchParams(params).toString();
                const response = await fetch(`${url}?${queryParams}`);
                
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const jsonData = await response.json();
                setData(jsonData);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [url, params]);

    return { data, loading, error };
};

export default useFetchData;
